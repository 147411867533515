import Modal from "react-bootstrap/Modal";
import TriageParentComponent from "../Protocol/TriageParentComponent";
import { useState } from "react";

const UpdateProtocolAssessmentModal = ({ encounterId, onShow, onHide }) => {
  const [triageBtnShow, setTriageBtnShow] = useState(false);

  const hideTriageBtn = () => {
    setTriageBtnShow(false);
  };
  return (
    <Modal
      backdropClassName
      backdrop={"static"}
      show={onShow}
      onHide={onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="custom-dialog"
    >
      <Modal.Header closeButton className="border-0">
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ textTransform: "capitalize" }}
        >
          Update Protocol Documentation
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TriageParentComponent
          encounterIdFromVisit={encounterId}
          fetchResponses={true}
          hideTriageBtn={hideTriageBtn}
        />
      </Modal.Body>
    </Modal>
  );
};

export default UpdateProtocolAssessmentModal;
