import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { decryptData } from "../../EncryptDecrypt";
import Background from "../../../assets/images/search-icon.png";

const DevicesSearchBar = ({
  list = [],
  filterData,
  encoded = false,
  placeholder = "Search...",
  page,
  setPage,
}) => {
  const [searchVal, setSearchVal] = useState("");
  const getName = (nameField) => {
    return typeof nameField === "string" ? nameField : nameField?.name;
  };
  const filter = () => {
    const data = encoded
      ? list?.filter((data) =>
          decryptData(getName(data?.deviceName))
            ?.toLowerCase()
            ?.includes(searchVal)
        )
      : list?.filter((data) =>
          getName(data?.deviceName)?.toLowerCase()?.includes(searchVal)
        );
    filterData(data);
  };
  useEffect(() => {
    filter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchVal, list]);
  return (
    <Form className="search-wrap">
      <Form.Control
        type="search"
        placeholder={placeholder}
        className="me-2"
        aria-label="Search"
        style={{ backgroundImage: searchVal ? "none" : `url(${Background})` }}
        value={searchVal}
        onChange={(e) => {
          page !== 0 && setPage(0);
          setSearchVal(e.target.value.toLowerCase());
        }}
      />
    </Form>
  );
};

export default DevicesSearchBar;
