import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import Creatable from "react-select/creatable";
import { Button } from "../../common/Button";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

const APLRuleModalWindow = ({
  data,
  onShow,
  onHide,
  action,
  existingRuleData = [],
  currentEditingRule = [],
  handleModalSubmit,
}) => {
  const [btnLoading, setBtnLoading] = useState(false);

  const operatorList = [
    { label: "equal", value: "equal" },
    { label: "not-equal", value: "not-equal" },
    { label: "contains", value: "contains" },
    { label: "does-not-contain", value: "does-not-contain" },
    { label: "contains-only", value: "contains-only" },
    { label: "greater-than", value: "greater-than" },
    { label: "greater-than-or-equal-to", value: "greater-than-or-equal-to" },
    { label: "less-than", value: "less-than" },
    { label: "less-than-or-equal-to", value: "less-than-or-equal-to" },
  ];

  const triggerQuestionOptions = data.map((question) => ({
    label: question?.text || question?.question,
    value: question?.id,
    type: question?.answerType,
    answerOption: question?.answerOption || question?.options || [],
  }));

  const validationSchema = Yup.object().shape({
    triggerQuestion: Yup.object().nullable().required("Required field"),
    operator: Yup.object().nullable().required("Required field"),
    triggerAnswer: Yup.string().required("Required field"),
    targetQuestion: Yup.object().nullable().required("Required field"),
  });

  const initialValues = {
    triggerQuestion:
      action === "UPDATE" && existingRuleData.length > 0
        ? triggerQuestionOptions.find(
            (option) =>
              option.value === currentEditingRule["trigger-question-id"]
          ) || null
        : null,

    operator:
      action === "UPDATE" && existingRuleData.length > 0
        ? operatorList.find(
            (option) => option.value === currentEditingRule.operator
          ) || null
        : null,

    triggerAnswer:
      action === "UPDATE" && existingRuleData.length > 0
        ? currentEditingRule["trigger-answer"] || ""
        : "",

    targetQuestion:
      action === "UPDATE" && existingRuleData.length > 0
        ? triggerQuestionOptions.find(
            (option) =>
              option.value === currentEditingRule["target-question-id"]
          ) || null
        : null,
  };

  let newIndexId =
    existingRuleData.length > 0
      ? existingRuleData[existingRuleData.length - 1]?.id + 1
      : 1;

  const handleSubmit = (values, { setSubmitting }) => {
    let rule = {
      id: action === "UPDATE" ? currentEditingRule?.id : newIndexId,
      "trigger-question-id": values.triggerQuestion?.value,
      "trigger-answer": values.triggerAnswer,
      operator: values.operator?.value,
      "target-question-id": values.targetQuestion?.value,
    };

    const ruleIndex = existingRuleData.findIndex(
      (existingRule) => existingRule.id === rule.id
    );

    if (ruleIndex > -1) {
      existingRuleData[ruleIndex] = rule;
    } else {
      existingRuleData.push(rule);
    }

    onHide();
  };

  return (
    <Modal
      backdrop="static"
      show={onShow}
      onHide={onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="custom-dialog"
    >
      <Modal.Header closeButton className="border-0">
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ textTransform: "capitalize" }}
        >
          Assessment Pathway Logic Rule:
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          validateOnChange={false}
          validateOnBlur={true}
        >
          {({ values, setFieldValue, isSubmitting, isValid }) => (
            <Form>
              <div className="row mb-3 align-items-center">
                <div className="col-md-3">
                  <Select
                    options={triggerQuestionOptions}
                    placeholder="Trigger Question"
                    onChange={(selectedOption) =>
                      setFieldValue("triggerQuestion", selectedOption)
                    }
                    value={values.triggerQuestion}
                  />
                  <ErrorMessage
                    name="triggerQuestion"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="col-md-3">
                  <Select
                    options={operatorList}
                    placeholder="Select Operator"
                    onChange={(selectedOption) =>
                      setFieldValue("operator", selectedOption)
                    }
                    value={values.operator}
                  />
                  <ErrorMessage
                    name="operator"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="col-md-3">
                  {values.triggerQuestion?.type === "freeText" ? (
                    <Creatable
                      isClearable
                      placeholder="Enter your answer"
                      onChange={(selectedOption) =>
                        setFieldValue(
                          "triggerAnswer",
                          selectedOption?.value || ""
                        )
                      }
                      value={{
                        label: values.triggerAnswer,
                        value: values.triggerAnswer,
                      }}
                    />
                  ) : (
                    <Select
                      options={values.triggerQuestion?.answerOption?.map(
                        (option) => ({
                          label: option?.text,
                          value: option?.text,
                        })
                      )}
                      placeholder="Select Answer"
                      onChange={(selectedOption) =>
                        setFieldValue("triggerAnswer", selectedOption?.value)
                      }
                      value={
                        values.triggerAnswer
                          ? {
                              label: values.triggerAnswer,
                              value: values.triggerAnswer,
                            }
                          : null
                      }
                    />
                  )}
                  <ErrorMessage
                    name="triggerAnswer"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="col-md-3">
                  <Select
                    options={triggerQuestionOptions.filter(
                      (option) => option.value !== values.triggerQuestion?.value
                    )}
                    placeholder="Action"
                    onChange={(selectedOption) =>
                      setFieldValue("targetQuestion", selectedOption)
                    }
                    value={values.targetQuestion}
                  />
                  <ErrorMessage
                    name="targetQuestion"
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>

              <div className="btn-wrap mt-4">
                <Button onClick={onHide} variant="secondary" title="Cancel">
                  Cancel
                </Button>
                <Button type="submit" isLoading={btnLoading || isSubmitting}>
                  {action === "UPDATE" ? "Update" : "Create"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default APLRuleModalWindow;
