import { useEffect, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import { getProtocolDefinitionById } from "../api/Protocol";
import { useSelector } from "react-redux";
import { failed } from "../../common/Toastify";
import ExpandableContent from "../../common/components/ExpandableContent";
import { FullPageSpinner } from "../../common/Spinner/FullPageSpinner";

const ProtocolDefinitionModal = ({
  protocol,
  selectedProtocolType,
  onShow,
  onHide,
}) => {
  const [protocolVersion, setProtocolVersion] = useState("2024");
  const [protocolInformation, setProtocolInformation] = useState(null);
  const [protocolInfoLoading, setProtocolInfoLoading] = useState(false);
  const userOrgId = useSelector(
    (state) => state?.auth?.user?.["organizations"][0].id
  );

  useEffect(() => {
    setProtocolInfoLoading(true);
    getProtocolDefinitionById(
      protocol?.value,
      protocolVersion,
      selectedProtocolType,
      userOrgId
    )
      .then((res) => {
        if (res?.status === true) {
          setProtocolInformation(res?.body?.[0]);
        }
      })
      .catch((res) => {
        failed(
          res?.response?.data?.message ||
            res?.response?.data?.error ||
            res?.message
        );
      })
      .finally(() => setProtocolInfoLoading(false));
  }, []);

  return (
    <section className="common-listing">
      {protocolInfoLoading && (
        <FullPageSpinner loadingText="Loading Protocol Defintion..." />
      )}
      <Modal
        backdropClassName
        backdrop={"static"}
        show={onShow}
        onHide={onHide}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="custom-dialog"
      >
        <Modal.Header closeButton className="border-0">
          <span className="header-text">Protocol Information:</span>&nbsp;&nbsp;
        </Modal.Header>
        <Modal.Body>
          <div className="table-wrap">
            <Table>
              <thead>
                <tr>
                  <th>
                    <ExpandableContent content="Protocol" />
                  </th>
                  <th>
                    <ExpandableContent
                      content={protocolInformation?.title || ""}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <ExpandableContent content="Title" />
                  </td>
                  <td>
                    <ExpandableContent
                      content={protocolInformation?.title || ""}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <ExpandableContent content="Background" />
                  </td>
                  <td>
                    <ExpandableContent
                      content={protocolInformation?.background || ""}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <ExpandableContent content="Definition" />
                  </td>
                  <td>
                    <ExpandableContent
                      content={protocolInformation?.definitionHTML || ""}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <ExpandableContent content="First Aid" />
                  </td>
                  <td>
                    <ExpandableContent
                      content={protocolInformation?.firstAid || ""}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default ProtocolDefinitionModal;
