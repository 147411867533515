import React, { useEffect, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import SelectFiled from '../../common/textfield/SelectFiled'
import Fields from '../Dashboard/Fields';
import { ExpandMore } from '@mui/icons-material';
import { isAnswered } from '../../../utils/questionnaireSupport';
import TextEditor from '../../common/textfield/TextEditor';

const evaluateCondition = (condition, questionResponse) => {
  const regex = /Questionnaire\.item\.where\(id='(\w+)'\)\.answer\.value\s*(contains|>|<|=|!=)\s*'(.*)'/g;
  let match;
  let result = true;

  while ((match = regex.exec(condition)) !== null) {
    const id = match[1];
    const operator = match[2];
    const value = match[3];

    // Find the response with the corresponding id
    const response = questionResponse.find(item => item.answerId === id);

    if (!response) {
      result = false; // If no matching response, condition is not met
      break;
    }

    const answerType = response?.answerType;
    const answers = response?.answer || [];

    if (answerType === 'checkbox' || answerType === 'radio') {
      // Evaluate based on checkbox/radio selection
      const selectedOptions = answers.filter(option => option.answer === true);
      switch (operator) {
        case 'contains':
          result = selectedOptions.some(item => item.option.includes(value));
          break;
        case '=':
          result = selectedOptions.some(item => item.option === value);
          break;
        case '!=':
          result = !selectedOptions.some(item => item.option === value);
          break;
        default:
          result = false; // Invalid operator for checkbox/radio
          break;
      }
    } else {
      const answer = answers[0]?.answer;
      // Evaluate based on other types
      switch (operator) {
        case 'contains':
          result = answer && answer.includes(value);
          break;
        case '>':
          result = answer && parseFloat(answer) > parseFloat(value);
          break;
        case '<':
          result = answer && parseFloat(answer) < parseFloat(value);
          break;
        case '=':
          result = answer && answer === value;
          break;
        case '!=':
          result = answer && answer !== value;
          break;
        default:
          result = false; // Invalid operator
          break;
      }
    }

    if (!result) break; // If any condition fails, stop evaluating
  }

  return result;
};

const QuestionnaireRenderer = ({
  formik,
  questionResponse,
  questionnaire,
  parentIndex,
  readOnly = false
}) => {
  const [visibleQuestions, setVisibleQuestions] = useState([]);

  useEffect(() => {
    const visibility = questionResponse.map((respItem, idx) => {
      const questionnaireItem = questionnaire?.item?.find(q => q.id === respItem.answerId);
      return questionnaireItem?.enableWhen
        ? evaluateCondition(questionnaireItem.enableWhen.expression, questionResponse)
        : true;
    });
    setVisibleQuestions(visibility);
  }, [questionResponse, questionnaire]);

  return questionResponse?.map((item, index) => {
    const isEnabled = visibleQuestions[index];
    if (!isEnabled) {
      return null; // Do not render if the condition is false
    }

    return (
      <Accordion key={index}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>
            {item?.question}
            {item?.required ? <span style={{ color: "red" }}> *</span> : null}
            {item?.required ? formik.errors.isQuestionsError && !isAnswered(item) && (
              <div className="error-message" style={{ color: "red", fontSize: "12px" }}>
                Mandatory Question
              </div>
            ) : null}
          </Typography>
        </AccordionSummary>

        {/* freeText question type */}
        {item.questionType === "freeText" && (
          <AccordionDetails sx={{ padding: 0 }}>
            <TextEditor
              key={`text-editor-${parentIndex}-${index}`}
              keyField={`questionResponse[${index}].answer[0].answer`}
              formik={formik}
              defaultValue={item?.answer?.[0]?.answer || ""}
              label={false}
              readOnly={readOnly}
            />
          </AccordionDetails>
        )}

        {/* radio or checkbox question types */}
        {(item.questionType === "radio" || item.questionType === "checkbox") && (
          <AccordionDetails sx={{ padding: 0 }}>
            <Fields
              question={item}
              formik={formik}
              Questions={questionResponse}
              propIndex={index}
              hideMandatory={true}
              readOnly={readOnly}
            />
          </AccordionDetails>
        )}

        {/* dropDown question type */}
        {item.questionType === "dropDown" && (
          <AccordionDetails sx={{ padding: 0 }}>
            <SelectFiled
              key={`dropdown-editor-${parentIndex}-${index}`}
              keyField={`questionResponse[${index}].answer[0].answer`}
              style={{ fontSize: 16 }}
              formik={formik}
              options={item.answerOption}
              readOnly={readOnly}
            />
          </AccordionDetails>
        )}
      </Accordion>
    )
  })
}

export default QuestionnaireRenderer;
