import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useFormik } from "formik";
import TextInput from "../../common/textfield/TextInput";
import SelectFiled from "../../common/textfield/SelectFiled";
import { Button } from "../../common/Button";
import { failed, success } from "../../common/Toastify";
import {
  QuestionnaireFormikObjEdit,
  questionnaireForOptions,
} from "./Constant";
import {
  editQuestionnaire,
  deleteQuestionnaire,
  getAPLRules,
  deleteAPLRules,
  updateAPLRules,
  createAPLRules,
  createQuestionnaire,
} from "../../api/Questionnaire";
import { NavigateNext, ExpandMore, ArrowBack, Help } from "@mui/icons-material";
import { Skeleton, Tooltip } from "@mui/material";
import "./style.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  TableCell,
} from "@mui/material";
import { useGetQuestionsByCategoryQuery } from "../../../hooks/ReactQueryHooks/useGetQuestionsByCategoryQuery";
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";
import { useSelector } from "react-redux";
import DeleteIcon from "../../../assets/images/cross-icon.png";
import { ViewDate } from "../../../utils/DateSupport";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";
import APLRuleModalWindow from "./APLRuleModalWindow";
import EditIcon from "../../../assets/images/edit.png";
import DeleteIconHome from "../../../assets/images/delete.png";
import { useGetOrgLicensedProducts } from "../../../hooks/ReactQueryHooks/useGetOrgLicensedProducts";

const EditQuestionnaire = () => {
  const location = useLocation();
  const { initialState, isReadOnly, actionType } = location.state || {};
  const [btnLoading, setBtnLoading] = useState(false);
  const [categorySelectedQuestions, setCategorySelectedQuestions] = useState(
    []
  );
  const [intialCategorySelectedQuestions, setIntialCategorySelectedQuestions] =
    useState([]);
  const [questionsCategoryList, setQuestionsCategoryList] = useState();
  const [err, setErr] = useState("");
  const { getConfirmation } = useConfirmDialog();
  const [deleting, setDeleting] = useState(false);
  const userType = useSelector(
    (state) => state?.auth?.user?.["cognito:groups"]
  );
  const userOrg = useSelector((state) => state?.auth?.user?.["custom:orgId"]);
  const hasAccess = userType?.includes("Super-Admin")
    ? true
    : !!!initialState?.isDefault;
  const navigate = useNavigate();
  const [isRulesLoading, setIsRulesLoading] = useState(true);
  const [rules, setRules] = useState([]);
  const [APLRuleModal, setAPLRuleModal] = useState(false);
  const [APLModalAction, setAPLModalAction] = useState("CREATE");
  const [currentEditingRule, setCurrentEditingRule] = useState(null);
  const userData = useSelector((state) => state?.auth?.user);
  const [ruleSet, setRuleSet] = useState([]);
  const [draggedRowIndex, setDraggedRowIndex] = useState(null);
  const questionnaireEditType = actionType;
  const { isFetched, data: orgLicensedProducts } = useGetOrgLicensedProducts({})
  const permissions = useSelector((state) => state?.auth?.user?.permissions);
  const isContactCenterActive = orgLicensedProducts?.callCenter;

  const formik = useFormik({
    ...QuestionnaireFormikObjEdit,
    onSubmit: (values) => {
      const tempQuestionsArr = [];
      const questionCounts = {
        LOCATION: 0,
        REASON: 0,
        TELEPHONE: 0,
        CHANNEL: 0,
        RELATIONSHIP: 0,
        SELFCALLING: 0,
        CONSENT: 0,
        CALLER: 0,
      };

      categorySelectedQuestions.forEach((question) => {
        tempQuestionsArr.push({
          text: question.question,
          id: question?.id || question.linkId,
          required: !!question.required,
          question: question.question,
          answerType: question.answerType,
          answerOption: question.answerOption || question.options,
          note: question.note,
          tag: question.tag || "",
          questionId: question?.id,
        });

        if (questionCounts.hasOwnProperty(question.tag)) {
          questionCounts[question.tag] += 1;
        }
      });

      if (formik.values?.purpose.includes("getcare")) {
        const requiredTags = ["REASON", "LOCATION", "TELEPHONE", "CHANNEL"];
        const missingQuestions = validateMissingQuestions(
          requiredTags,
          questionCounts
        );

        if (missingQuestions.length > 0) {
          setErr(`Must Select These Questions: ${missingQuestions.join(", ")}`);
          return;
        }

        if (!validateDuplicateQuestions(requiredTags, questionCounts)) return;
      }

      if (formik.values?.purpose.includes("telephone-intake")) {
        const requiredTags = [
          "REASON",
          "LOCATION",
          "TELEPHONE",
          "RELATIONSHIP",
          "SELFCALLING",
          "CONSENT",
          "CALLER",
        ];
        const missingQuestions = validateMissingQuestions(
          requiredTags,
          questionCounts
        );

        if (missingQuestions.length > 0) {
          setErr(`Must Select These Questions: ${missingQuestions.join(", ")}`);
          return;
        }

        if (!validateDuplicateQuestions(requiredTags, questionCounts)) return;
      }

      if (
        formik.values?.purpose.includes("Video Visit") &&
        categorySelectedQuestions.length === 0
      ) {
        setErr("Must Select a Question");
        return;
      }

      setBtnLoading(true);
      values.isDefault = values.isDefault ? values.isDefault : false;
      const apiCall =
        questionnaireEditType === "CREATE"
          ? createQuestionnaire({
              ...values,
              question: tempQuestionsArr,
              status: "active",
            })
          : editQuestionnaire(initialState?.id, {
              ...values,
              question: tempQuestionsArr,
            });
      apiCall
        .then((res) => {
          success(res.message);
          if (rules?.length > 0 && ruleSet?.length === 0) {
            deleteAPLRules(initialState.id, userOrg)
              .then((res) => {
                console.log("Rules deleted");
              })
              .catch((res) => {
                failed(
                  res?.response?.data?.message ||
                    res?.response?.data?.error ||
                    res?.message
                );
              });
          } else if (rules?.length === 0 && ruleSet?.length > 0) {
            let payloadForCreateRules = {
              orgId: userOrg,
              createdBy: {
                reference: `Practitioner/${userData?.["custom:practitioner_id"]}`,
                display: userData?.name[0]?.text,
              },
              rules: ruleSet,
              questionnaireId:
                questionnaireEditType === "CREATE"
                  ? res?.data?.id
                  : initialState?.id,
            };
            createAPLRules(
              questionnaireEditType === "CREATE"
                ? res?.data?.id
                : initialState.id,
              userOrg,
              payloadForCreateRules
            )
              .then((res) => {
                console.log("Rules created");
              })
              .catch((res) => {
                failed(
                  res?.response?.data?.message ||
                    res?.response?.data?.error ||
                    res?.message
                );
              });
          } else if (rules?.length > 0 && ruleSet?.length > 0) {
            let payloadForUpdateRules = {
              orgId: userOrg,
              updatedBy: {
                reference: `Practitioner/${userData?.["custom:practitioner_id"]}`,
                display: userData?.name[0]?.text,
              },
              rules: ruleSet,
              questionnaireId: initialState?.id,
            };
            updateAPLRules(initialState?.id, userOrg, payloadForUpdateRules)
              .then((res) => {
                console.log("Rules updated");
              })
              .catch((res) => {
                failed(
                  res?.response?.data?.message ||
                    res?.response?.data?.error ||
                    res?.message
                );
              });
          } else {
            console.log("No Update to APL rules");
          }
        })
        .catch((res) => {
          failed(
            res?.response?.data?.message ||
              res?.response?.data?.error ||
              res.message
          );
        })
        .finally(() => {
          setBtnLoading(false);
          navigate(-1);
        });
    },
  });

  const validateMissingQuestions = (requiredTags, questionCounts) => {
    const missingQuestions = [];

    requiredTags.forEach((tag) => {
      if (questionCounts[tag] === 0) {
        const readableTag = {
          LOCATION: "Patient Location",
          REASON: "Reason for visit",
          TELEPHONE: "Return Telephone Number",
          CHANNEL: "Preferred Channel",
          RELATIONSHIP: "Relationship to Patient",
          SELFCALLING: "Calling for Yourself",
          CONSENT: "Patient Consent",
          CALLER: "Caller Name",
        }[tag];
        missingQuestions.push(readableTag);
      }
    });

    return missingQuestions;
  };

  useEffect(() => {
    if (isContactCenterActive) {
      setIsRulesLoading(true);
      getAPLRules(initialState.id, userOrg)
        .then((res) => {
          if (res?.status === true || res?.status === "true") {
            {
              questionnaireEditType !== "CREATE" &&
                setRules(res?.data?.[0]?.rules || []);
            }
            setRuleSet(res?.data?.[0]?.rules || []);
          }
        })
        .catch((res) => {
          failed(res?.message);
        })
        .finally(() => {
          setIsRulesLoading(false);
        });
      }
  }, []);

  const validateDuplicateQuestions = (tags, questionCounts) => {
    for (const tag of tags) {
      if (questionCounts[tag] > 1) {
        const readableTag = {
          LOCATION: "Patient Location",
          REASON: "Reason for visit",
          TELEPHONE: "Return Telephone Number",
          CHANNEL: "Preferred Channel",
          RELATIONSHIP: "Relationship to Patient",
          SELFCALLING: "Calling for Yourself",
          CONSENT: "Patient Consent",
          CALLER: "Caller Name",
        }[tag];
        setErr(
          `You Have Selected More Than One Question Marked With ${readableTag}`
        );
        return false;
      }
    }
    return true;
  };

  const onSuccess = (data) => {
    setQuestionsCategoryList(data);
    setCategorySelectedQuestions(
      intialCategorySelectedQuestions?.length
        ? intialCategorySelectedQuestions
        : []
    );
    intialCategorySelectedQuestions?.length &&
      setIntialCategorySelectedQuestions([]);
    setErr("");
  };
  const { isLoading, isFetching } = useGetQuestionsByCategoryQuery({
    onSuccess,
    purpose: formik?.values?.purpose,
    orgId: userOrg,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (initialState?.id) {
      formik.setFieldValue(
        "title",
        questionnaireEditType === "CREATE" ? "" : initialState?.title || ""
      );
      formik.setFieldValue("purpose", initialState?.purpose || "");
      formik.setFieldValue("time", initialState?.duration?.value);
      formik.setFieldValue("isDefault", initialState?.isDefault || "");
      setIntialCategorySelectedQuestions(
        initialState?.item?.map((item) => ({ ...item, question: item?.text }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialState]);

  const handleSelectedQuestions = (question) => {
    setErr("");
    if (!isReadOnly) {
      if (
        !categorySelectedQuestions?.some(
          (item) => item?.question === question?.question
        )
      ) {
        const newArr = [question, ...categorySelectedQuestions];
        setCategorySelectedQuestions(newArr);
        setErr("");
      } else {
        const newArr = categorySelectedQuestions.filter(
          (item) => item?.question !== question?.question
        );
        setCategorySelectedQuestions(newArr);
        setErr("");
      }
    }
  };

  if (!initialState) {
    return null;
  }

  const isSafeToDelete = (questionId) => {
    const found = ruleSet?.some(
      (rule) =>
        rule["trigger-question-id"] === questionId ||
        rule["target-question-id"] === questionId
    );
    return !found;
  };

  const handleDrop = (droppedItem) => {
    setErr("");
    if (!droppedItem.destination) return;
    var updatedList = [...categorySelectedQuestions];
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    setCategorySelectedQuestions(updatedList);
    setErr("");
  };

  const handleDelete = async (id, entryName, purposeData) => {
    const confirmed = await getConfirmation({
      title: "Attention!",
      entryName,
      actionBtnTitle: "Delete",
    });
    if (confirmed) {
      setDeleting(initialState?.id);
      let payload = {
        status: "retired",
        purpose: purposeData,
        type: "questionnaire",
      };
      deleteQuestionnaire(initialState?.id, payload)
        .then(() => {
          success("Questionnaire Deleted!");
        })
        .catch((res) =>
          failed(
            res?.response?.data?.message ||
              res?.response?.data?.error ||
              res.message
          )
        )
        .finally(() => setDeleting(false));
    }
  };

  const handleCancelClick = async (entryName) => {
    const confirmed = await getConfirmation({
      title: "Attention!",
      entryName,
      actionBtnTitle: "Yes",
      message: "Are you sure you would like to ",
    });
    if (confirmed) {
      navigate(-1);
    }
  };

  const handleDeleteQuestion = (index, item) => {
    if (isSafeToDelete(item?.id) === false) {
      failed(
        "There is an active rule in place for this question. Remove rule and try again"
      );
      return;
    }

    setErr("");
    const updatedList = [...categorySelectedQuestions];
    updatedList.splice(index, 1);
    setCategorySelectedQuestions(updatedList);
  };

  const handleEditAPLRulesClick = (data) => {
    setCurrentEditingRule(data);
    setAPLModalAction("UPDATE");
    setAPLRuleModal(true);
  };

  const handleDeleteAPLRuleClick = async (data) => {
    const confirmed = await getConfirmation({
      title: "Attention!",
      entryName: "delete rule",
      actionBtnTitle: "Yes",
      message: "Are you sure you would like to ",
    });
    if (confirmed) {
      setCurrentEditingRule(data);
      const transformedRules = ruleSet
        .filter((rule) => rule.id !== data.id)
        .map((rule, index) => ({ ...rule, id: index + 1 }));
      setRuleSet(transformedRules);
    }
  };

  const handleModalSubmit = (data) => {
    console.log("This will update data from modal: ", data);
  };

  const handleDragStart = (index) => {
    setDraggedRowIndex(index);
  };

  const handleDragOver = (event) => {
    event.preventDefault(); // Necessary for the onDrop event to work
  };

  const handleDropRule = (index) => {
    const draggedRule = ruleSet[draggedRowIndex];
    const updatedRules = [...ruleSet];
    updatedRules.splice(draggedRowIndex, 1);
    updatedRules.splice(index, 0, draggedRule);
    const updatedRulesWithIds = updatedRules.map((rule, idx) => ({
      ...rule,
      id: idx + 1,
    }));
    setRuleSet(updatedRulesWithIds);
  };

  return (
    <section className="common-listing">
      {APLRuleModal && (
        <APLRuleModalWindow
          onShow={APLRuleModal}
          onHide={() => setAPLRuleModal(false)}
          action={APLModalAction}
          data={categorySelectedQuestions}
          questionnaireId={initialState.id}
          existingRuleData={ruleSet}
          currentEditingRule={currentEditingRule}
          handleModalSubmit={handleModalSubmit}
        />
      )}
      <div className="heading-wrap mb-3">
        <Tooltip title="Go back">
          <div
            style={{ marginRight: "1rem", cursor: "pointer" }}
            onClick={() => navigate(-1)}
          >
            <ArrowBack />
          </div>
        </Tooltip>
      </div>
      <div className="custom-card p-4">
        <form
          className="common-form border-fields"
          onSubmit={formik.handleSubmit}
        >
          <Row>
            <Col>
              <TextInput
                disabled={isReadOnly}
                keyField={"title"}
                label={"Title"}
                formik={formik}
                placeholder={"Title"}
              />
            </Col>
            <Col>
              <SelectFiled
                disabled={isReadOnly}
                keyField={"purpose"}
                label={"Purpose"}
                formik={formik}
                placeholder={"Select"}
                options={questionnaireForOptions}
                value={formik.values?.purpose}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <h4>Available Categories</h4>
              {isLoading || isFetching ? (
                <ul className="questionnaire-list">
                  {[1, 2, 3, 4, 5, 6, 7]?.map((val) => (
                    <li key={val}>
                      <Skeleton width={"100%"} animation="wave" />
                    </li>
                  ))}
                </ul>
              ) : (
                <ul className="questionnaire-list">
                  {questionsCategoryList?.map((category) => (
                    <Accordion key={category?.id}>
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography>{category?.categoryName}</Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ padding: 0 }}>
                        <ul className="questionnaire-list">
                          {category?.questions?.map((question) => (
                            <li
                              key={question?.linkId}
                              onClick={() =>
                                handleSelectedQuestions(question, category)
                              }
                            >
                              {question?.question}
                              <NavigateNext style={{ float: "right" }} />
                            </li>
                          ))}
                        </ul>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </ul>
              )}
            </Col>
            <Col>
              <h4>Selected Questions</h4>
              {categorySelectedQuestions?.length ? null : (
                <div>No available question selected</div>
              )}
              <Tooltip title={"Drag to Reorder Questions"}>
                <ul className="questionnaire-list">
                  {!isReadOnly ? (
                    <DragDropContext onDragEnd={handleDrop}>
                      <Droppable droppableId="list-container">
                        {(provided) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {categorySelectedQuestions.map((item, index) => (
                              <Draggable
                                key={item?.question}
                                draggableId={item?.question}
                                index={index}
                              >
                                {(provided) => (
                                  <li
                                    key={index}
                                    ref={provided.innerRef}
                                    {...provided.dragHandleProps}
                                    {...provided.draggableProps}
                                  >
                                    <div>
                                      <span> {item?.question} </span>
                                    </div>
                                    <img
                                      src={DeleteIcon}
                                      alt="Delete"
                                      onClick={() =>
                                        handleDeleteQuestion(index, item)
                                      }
                                      style={{ cursor: "pointer" }}
                                    />
                                  </li>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  ) : (
                    categorySelectedQuestions?.map((item, key) => (
                      <li
                        key={key}
                        onClick={() => handleSelectedQuestions(item)}
                      >
                        <div>
                          {" "}
                          <span> {item.text} </span>
                        </div>
                      </li>
                    ))
                  )}
                </ul>
              </Tooltip>
              <div
                style={{ fontSize: "14px", color: "#dc3545", addingTop: "5px" }}
              >
                {err}
              </div>
            </Col>
          </Row>
          <hr />
          {(isContactCenterActive && permissions.includes("Manage Questionnaire")) && <div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginBottom: "10px",
                gap: "10px",
              }}
            >
              <h4 style={{ marginRight: "auto" }}>
                <Tooltip title="The answer to the Trigger Question determines when the Target Question is enabled.">
                  Rules <Help />
                </Tooltip>
              </h4>{" "}
              {(questionnaireEditType === "CREATE" ||
                questionnaireEditType === "UPDATE") && (
                <Button
                  onClick={() => {
                    setAPLModalAction("CREATE");
                    setAPLRuleModal(true);
                  }}
                  isLoading={btnLoading}
                  style={{ padding: "5px 10px" }}
                >
                  Create Rule
                </Button>
              )}
            </div>
            <div className="table-wrap">
              <Table>
                <thead>
                  <tr>
                    <th>Index</th>
                    <th>Trigger Question</th>
                    <th>Operator</th>
                    <th>Trigger Answer</th>
                    <Tooltip title="The Action will be disabled by default and enabled when the Trigger Question's answer evaluates to true.">
                      <th>
                        Action <Help />
                      </th>
                    </Tooltip>
                    {questionnaireEditType !== "VIEW" && <th>Configure</th>}
                  </tr>
                </thead>
                <tbody>
                  {isRulesLoading ? (
                    Array.from({ length: 5 }).map((_, rowIndex) => (
                      <tr key={rowIndex}>
                        {Array(6)
                          .fill()
                          .map((_, colIndex) => (
                            <td key={colIndex}>
                              <Skeleton animation="wave" />
                            </td>
                          ))}
                      </tr>
                    ))
                  ) : ruleSet?.length > 0 ? (
                    ruleSet.map((rule, index) => {
                      const currentItem = initialState?.item.find(
                        (item) => item.id === rule?.["trigger-question-id"]
                      );
                      const targetItem = initialState?.item.find(
                        (item) => item.id === rule?.["target-question-id"]
                      );
                      return (
                        <Tooltip title="Drag to reorder rules">
                          <tr
                            key={rule?.id}
                            draggable
                            onDragStart={() => handleDragStart(index)}
                            onDragOver={handleDragOver}
                            onDrop={() => handleDropRule(index)}
                          >
                            <td>{rule?.id}</td>
                            <td>{currentItem?.text}</td>
                            <td>{rule?.operator}</td>
                            <td>{rule?.["trigger-answer"]}</td>
                            <td>{targetItem?.text}</td>
                            {questionnaireEditType !== "VIEW" && (
                              <td>
                                <div className="action-wrap">
                                  <Tooltip title="Edit">
                                    <div
                                      variant="link"
                                      onClick={() =>
                                        handleEditAPLRulesClick(rule)
                                      }
                                      className="success-btn"
                                    >
                                      <img src={EditIcon} alt="Edit" />
                                    </div>
                                  </Tooltip>
                                  <Tooltip title={"Delete"}>
                                    {" "}
                                    <div
                                      variant="link"
                                      onClick={() =>
                                        handleDeleteAPLRuleClick(rule)
                                      }
                                      className="delete-btn"
                                    >
                                      {" "}
                                      <img
                                        src={DeleteIconHome}
                                        alt="Delete"
                                      />{" "}
                                    </div>
                                  </Tooltip>
                                </div>
                              </td>
                            )}
                          </tr>
                        </Tooltip>
                      );
                    })
                  ) : (
                    <tr>
                      <TableCell colSpan={"8"} style={{ textAlign: "center" }}>
                        No Data Available
                      </TableCell>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>}

          <hr />
          <Row>
            {questionnaireEditType !== "CREATE" && (
              <Col>
                <div>
                  <h6>Date Created : {ViewDate(initialState?.createdAt)}</h6>
                  <h6>
                    Date Last Updated : {ViewDate(initialState?.updatedAt)}{" "}
                  </h6>
                </div>
              </Col>
            )}
            <Col>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {!isReadOnly ? (
                  <>
                    <div>
                      <Row>
                        <Button
                          variant="secondary"
                          title="Cancel"
                          onClick={() =>
                            handleCancelClick("cancel editing questionnaire")
                          }
                        >
                          Cancel
                        </Button>

                        <Button
                          type="submit"
                          isLoading={btnLoading}
                          style={{
                            marginLeft: "10px",
                          }}
                        >
                          {questionnaireEditType === "CREATE"
                            ? "Create"
                            : "Update"}
                        </Button>

                        {questionnaireEditType === "UPDATE" && (
                          <Button
                            variant="secondary"
                            title="Delete"
                            onClick={() =>
                              hasAccess &&
                              (deleting
                                ? null
                                : handleDelete(
                                    initialState?.id,
                                    initialState?.title,
                                    initialState?.purpose
                                  ))
                            }
                            style={{
                              marginLeft: "10px",
                            }}
                          >
                            Delete
                          </Button>
                        )}
                      </Row>
                    </div>
                  </>
                ) : null}
              </div>
            </Col>
          </Row>
          <div>
            {formik?.touched["question"] && formik?.errors["question"] ? (
              <div className="error-text">{formik?.errors["question"]}</div>
            ) : null}
          </div>
        </form>
      </div>
    </section>
  );
};

export default EditQuestionnaire;
