import { axios } from "../../../../lib/axios";

export const getSearhWordsByKeyword = (
  keyword,
  version,
  protocolCategory,
  orgId
) => {
  return axios.get(
    `/protocol/searchwords?searchword=${keyword}&version=${version}&category=${protocolCategory}&orgId=${orgId}`
  );
};

export const getProtocolsBySearchWord = (
  searchword,
  version,
  protocolCategory,
  orgId
) => {
  return axios.get(
    `/protocol/search?keyword=${searchword}&version=${version}&category=${protocolCategory}&orgId=${orgId}`
  );
};

export const getProtocolDefinitionById = (
  protocolId,
  version,
  protocolCategory,
  orgId
) => {
  return axios.get(
    `/protocol/definition/${protocolId}?version=${version}&category=${protocolCategory}&orgId=${orgId}`
  );
};

export const getProtocolByProtocolId = (
  protocolId,
  version,
  protocolCategory,
  orgId
) => {
  return axios.get(
    `/protocol/${protocolId}?version=${version}&category=${protocolCategory}&orgId=${orgId}`
  );
};

export const getProtocolResponseById = (
  protocolResponseId,
  encounterId,
  orgId
) => {
  return axios.get(
    `/protocolresponse/${protocolResponseId}/encounter/${encounterId}/org/${orgId}`
  );
};

export const getProtocolResponsesByEncounterId = (encounterId, orgId) => {
  return axios.get(`/protocolresponse/encounter/${encounterId}/org/${orgId}`);
};

export const createProtocolResponse = (orgId, payload) => {
  return axios.post(`/protocolresponse/org/${orgId}`, payload);
};

export const patchProtocolResponse = (
  protocolResponseId,
  encounterId,
  orgId,
  payload
) => {
  return axios.patch(
    `/protocolresponse/${protocolResponseId}/encounter/${encounterId}/org/${orgId}`,
    payload
  );
};
