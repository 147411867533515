import { useFormik } from "formik";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import TextInput from "../../common/textfield/TextInput";
import CountrySelect from "../../common/textfield/CountrySelect";
import RegionSelect from "../../common/textfield/RegionSelect";
import SelectFiled from "../../common/textfield/SelectFiled";
import { Button } from "../../common/Button";
import { ViewDate } from "../../../utils/DateSupport";

import UpdateDetailsIcon from "../../../assets/images/update.svg";
import { editDevice } from "../../api/Individual";
import { failed, success } from "../../common/Toastify";

const EditKioskDeviceModal = ({
  deviceData,
  onShow,
  onHide,
  hasAccessToEdit,
}) => {
  const [btnLoading, setBtnLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      deviceName: deviceData?.deviceName?.name || "",
      location: deviceData?.location || "",
      country: deviceData?.location?.split("-")[0] || "",
      email: deviceData?.email || "",
      region: deviceData?.location?.split("-")[1] || "",
      status: deviceData?.status || "",
      organization: deviceData?.managingOrganization?.display || "",
    },
    validationSchema: Yup.object({
      deviceName: Yup.string()
        .max(50, "Must be 50 characters or less")
        .required("Required first name field")
        .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
      country: Yup.string()
        .required("Required country field")
        .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
      region: Yup.string()
        .required("Required region field")
        .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
    }),
    onSubmit: (values) => {
      setBtnLoading(true);
      let payload = {
        deviceName: values?.deviceName,
        location: values?.country + "-" + values?.region,
        status: values?.status,
        active: values?.status === "inactive" ? false : true,
      };

      editDevice(deviceData?.id, deviceData?.orgId, payload)
        .then((res) => {
          if (res?.status === true) {
            success("Device information updated");
            onHide();
          }
        })
        .catch((res) => failed(res?.message || res?.result?.message))
        .finally(() => setBtnLoading(false));
    },
  });

  const statusOptions = [
    { name: "Active", value: "active" },
    { name: "Inactive", value: "inactive" },
  ];

  const handleStatusChange = (event) => {
    formik.setFieldValue("status", event?.target?.value);
  };

  return (
    <Modal
      backdropClassName
      backdrop={"static"}
      show={onShow}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="custom-dialog"
    >
      <Modal.Header closeButton className="border-0">
        <Modal.Title id="contained-modal-title-vcenter">
          Edit Device Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="common-form border-fields userAccount">
          <Row>
            <Col>
              <TextInput
                style={{
                  textTransform: "capitalize",
                }}
                type={"text"}
                keyField={"deviceName"}
                label={"Device Name"}
                formik={formik}
                placeholder={"Device Name"}
                required={true}
                readOnly={!hasAccessToEdit}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextInput
                type={"email"}
                keyField={"email"}
                label={"Email Address"}
                formik={formik}
                placeholder={"Email Address"}
                required={true}
                readOnly={true}
                disabled={true}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <CountrySelect
                keyField={"country"}
                label={"Country"}
                formik={formik}
                readOnly={!hasAccessToEdit}
              />
            </Col>
            <Col>
              <RegionSelect
                defaultOptionLabel={
                  formik?.values?.country === "US"
                    ? "Select State"
                    : "Select Province"
                }
                keyField={"region"}
                label={formik?.values?.country === "US" ? "State" : "Province"}
                formik={formik}
                readOnly={!hasAccessToEdit}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextInput
                style={{
                  textTransform: "capitalize",
                }}
                type={"text"}
                keyField={"organization"}
                label={"Organization"}
                formik={formik}
                placeholder={"Organization"}
                required={true}
                readOnly={true}
                disabled={true}
              />
            </Col>
            <Col>
              <SelectFiled
                keyField={"status"}
                label={"Status"}
                formik={formik}
                options={statusOptions}
                readOnly={false}
                onChange={handleStatusChange}
              />
            </Col>
          </Row>
          {hasAccessToEdit && (
            <Row>
              <Col>
                <Button
                  style={{ marginRight: "20px" }}
                  onClick={() => {
                    onHide();
                  }}
                  variant="secondary"
                  title="Cancel"
                >
                  Cancel
                </Button>
                <Button isLoading={btnLoading} onClick={formik.handleSubmit}>
                  <img src={UpdateDetailsIcon} /> Update
                </Button>
              </Col>

              <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                <div style={{ alignSelf: "flex-end" }}>
                  Device Created on {ViewDate(deviceData?.createdAt)}
                </div>
              </Col>
            </Row>
          )}
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default EditKioskDeviceModal;
