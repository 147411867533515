import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as Yup from "yup";
import { Field, useFormik } from "formik";
import TextInput from "../../common/textfield/TextInput";
import SelectFiled from "../../common/textfield/SelectFiled";
import { Button } from "../../common/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector } from 'react-redux';
import { failed, success } from '../../common/Toastify';
import { isAnswered } from '../../../utils/questionnaireSupport';
import { getOrganization } from '../../api/Organization';
import { createQuestionnaireResponse, editQuestionnaireResponse, getVideoVisitQuestionnaire } from '../../api/Questionnaire';
import { editEncounter, getEncounterRecord, getQueueRecord, updateAppointmentRequest, getEncounterQuestionnaireResponses } from '../../apps/api/AppointmentRequests';
import { QuestionnaireFormikObj } from '../../Zoom/constant';
import QuestionnaireRenderer from '../../Admin/Questionnaire/QuestionnaireRenderer'

export function UpdateEncounterDocumentation({
  modalShow,
  handleShow,
  encounterId,
  patientId
}) { 
  const [btnLoading, setBtnLoading] = useState(false); 
  const adminOrgs = useSelector((state) => state?.auth?.user?.organizations)
  const [questionnaireList, setQuestionnaireList] = useState([]);
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [textEditorKey, setTextEditorKey] = useState(0);
  const [savedQuestionnaireResponses, setSavedQuestionnaireResponses] = useState([]);
  const user = useSelector((state) => state?.auth?.user);
  const [canEditSelectedQuestionnaire, setCanEditSelectedQuestionnaire] = useState(true);

  const formik = useFormik({
          ...QuestionnaireFormikObj,
          onSubmit: (values, { setErrors, setFieldValue }) => {
              return new Promise((resolve, reject) => {
                if(!selectedQuestionnaire){
                    failed("Select questionnaire before proceeding");
                    return;
                }
                for (let i = 0; i < values?.questionResponse.length; i++) {
                    const item = values?.questionResponse[i];
                    if (item.required && !isAnswered(item)) {
                        setErrors({ isQuestionsError: `Question : ${item.question} is mandatory.` });
                        reject(`Question : ${item.question} is mandatory.`);
                        return;
                    }
                }
                setBtnLoading(true);
                setIsDisabled(false) 
                let QRid = values.questionResponseId;
                if(QRid){
                    editQuestionnaireResponse(QRid, { patientID: patientId, encounterId: encounterId, questionResponse: values?.questionResponse, appointmentId: "" , questionnaire: selectedQuestionnaire, authorName: user?.name[0]?.text, authorType: "Practitioner", authorId: user["custom:practitioner_id"] })
                        .then(async (res) => {  
                            setFieldValue("questionResponseId", res.data.id) 
                            const encounterResponses = await getEncounterQuestionnaireResponses({ encounterId: encounterId });
                            setSavedQuestionnaireResponses(encounterResponses); 
                            success(res.message);
                            handleShow();
                            resolve(); 

                        })
                        .catch((res) => {
                            failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                            reject(res);
                        })
                        .finally(() => {
                            setBtnLoading(false);
                        });
                } else {
                    createQuestionnaireResponse({ patientID: patientId, encounterId: encounterId, questionResponse: values?.questionResponse, appointmentId: "", questionnaire: selectedQuestionnaire, authorName: user?.name[0]?.text, authorType: "Practitioner", authorId: user["custom:practitioner_id"] })
                        .then(async (res) => { 
                            setFieldValue("questionResponseId", res.data.id) 
                            const encounterResponses = await getEncounterQuestionnaireResponses({ encounterId: encounterId });
                            setSavedQuestionnaireResponses(encounterResponses); 
                            success(res.message);
                            handleShow(); 
                            resolve();
                        })
                        .catch((res) => {
                            failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                            reject(res);
                        })
                        .finally(() => {
                            setBtnLoading(false);
                        });
                }
            });
          },
      });

  useEffect(() => {
    const fetchData = async () => {
        try {
            const res = await getOrganization(adminOrgs?.[0]?.id);
            const assignedQuestionnaire = res?.data?.assignedQuestionnaire || {};
            const videoVisitIds = assignedQuestionnaire["video-visit"] || [];
            let questionnaireIdsList = [];
            if('video-visit' in assignedQuestionnaire && videoVisitIds.length > 0) {
                const VVRes = await getVideoVisitQuestionnaire(JSON.stringify(videoVisitIds), adminOrgs?.[0]?.id)
                const modifiedData = VVRes?.data?.map(item => ({
                    ...item,
                    name: item.title,
                    value: item.id
                }));
                questionnaireIdsList = modifiedData.map(item => item.id);
                setQuestionnaireList(modifiedData);
            }
            const responseData = await getEncounterQuestionnaireResponses({ encounterId: encounterId }); 
            setSavedQuestionnaireResponses(responseData);
            
            if ( responseData && Array.isArray(responseData.data) && responseData.data.every(item => typeof item.updatedAt === "number") ) {
                // Sort data by `updatedAt` in descending order to get the latest questionnaire
                const sortedData = [...responseData.data].sort((a, b) => b.updatedAt - a.updatedAt);
                const latestQuestionnaireId = sortedData?.[0]?.questionnaire; 
                if (!questionnaireIdsList.includes(latestQuestionnaireId) || !latestQuestionnaireId) return; 
             
                // Filter records with the latest questionnaire ID and sort by `authored`
                const filteredData = responseData.data
                    .filter(record => record.questionnaire === latestQuestionnaireId)
                    .sort((a, b) => b.authored - a.authored);
            
                const latest = filteredData.length > 0 ? filteredData[0] : null;
                let questionResponse = [];
            
                if (latest?.item?.length > 0) { 
                    const canEdit = checkCanEditAuthor(latest); 
                    questionResponse = canEdit
                        ? setPreviousQuestionnaire(latest)  // Use previous questionnaire if the author is the same
                        : setEmptyQuestionnaire(latestQuestionnaireId); // Otherwise, blank it out
            
                    if (canEdit) {
                        formik.setFieldValue("questionResponseId", latest.id);
                        setSelectedQuestionnaire(latestQuestionnaireId); 
                    }
                } else { 
                    // Brand new blank questionnaire
                    questionResponse = setEmptyQuestionnaire(latestQuestionnaireId);
                }
                setCanEditSelectedQuestionnaire(true);
                formik.setFieldValue("questionResponse", questionResponse);
            } 
        } catch (error) {
            console.error('Error fetching organization data:', error);
        }
    };
    fetchData();
  }, []);


  const setEmptyQuestionnaire = (qId) => {
    return questionnaireList
        .filter(item => item?.id === qId)?.[0]?.item
        .map((item , index) => ({
            id: item?.linkId,
            question: item?.text,
            key:{index},
            questionType: item?.answerType,
            answerType: item?.answerType,
            answerOption: item?.answerOption?.map(x => ({value: ((x.value) ? x.value : x.text), name: (x.text) ? x.text: x.value, text: x.text, option: (x.value) ? x.value : x.text})),
            answer: item.answerOption?.map(item => ({ answer: "", id: item?.id, option: (item?.value) ? item.value : item?.text })),
            required: item?.required,
        }))
}

const setPreviousQuestionnaire = (questionResponse) => {
    return questionResponse?.item?.map((questionItem, index) => ({
        id: questionItem?.linkId,
        question: questionItem?.text,
        key: { index },
        questionType: questionItem?.questionType,
        answerType: questionItem?.questionType,
        answerOption: questionItem?.answer?.map(x => ({
            value:  x?.option,
            name: x?.option,
            text: x?.option,
            option: x?.option
        })),
        answer: questionItem?.answer?.map(answerItem => ({
            answer: answerItem?.valueString || answerItem?.ratingText || answerItem?.valueBoolean || "",
            id: questionItem?.linkId,
            option: answerItem?.option || answerItem?.valueString
        })),
        required: questionItem?.required
    }));
};


  const handleSelectChange = async (event) => {
    const selectedQId = event.target.value;
        let questionResponse = [];
        let canEdit = true; // default to true, refine below if a different author is found 
        formik.setFieldValue("questionResponseId", null);
        try {
          
            // Attempt to find the latest DB-saved questionnaireResponse for that Q
            let latestResponse = null;
            if (savedQuestionnaireResponses && savedQuestionnaireResponses.data) {
                const filteredData = savedQuestionnaireResponses.data
                .filter((r) => r.questionnaire === selectedQId)
                .sort((a, b) => b.authored - a.authored); // newest first
                latestResponse = filteredData.length > 0 ? filteredData[0] : null;
            }

            if (latestResponse?.item?.length > 0) {
                // We have an existing response in DB for that Q
                canEdit = checkCanEditAuthor(latestResponse);
                if (!canEdit) {
                    // Different author => new blank
                    questionResponse = setEmptyQuestionnaire(selectedQId); 
                } else {
                    // Same author => load the existing data
                    questionResponse = setPreviousQuestionnaire(latestResponse);
                    formik.setFieldValue("questionResponseId", latestResponse.id); 
                    }
            } else { 
                questionResponse = setEmptyQuestionnaire(selectedQId); 
            } 
        } catch (error) {
          console.error("Error retrieving questionnaire response:", error);
        }
    
        // Update formik + local state
        setSelectedQuestionnaire(selectedQId);
        formik.setFieldValue("selectedQId", selectedQId);
        formik.setFieldValue("questionResponse", questionResponse);
        setTextEditorKey((prevKey) => prevKey + 1);  
        // Finally, set or reset "canEdit" for the newly selected Q
        setCanEditSelectedQuestionnaire(true);  
};

const checkCanEditAuthor = (responseObj) => {
    if (!responseObj?.author?.reference) return true;
    const authorRef = responseObj.author.reference; // e.g. "Practitioner/12345..."
    const authorId = authorRef.split("/")[1];
    // Return true if the same as current user, false if not
    return authorId === user["custom:practitioner_id"];
  }

  return (
    <Modal
      backdropClassName
      backdrop={"static"}
      show={modalShow}
      onHide={handleShow}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="custom-dialog"
      style={{ width: "100%" }} 
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        Questionnaire
        </Modal.Title>
         
      </Modal.Header>
      <Modal.Body>
      <div className="questionnair-wrapper">
                
                <div className="chat-sidebar" >
                    <div className="individual-chart-wrap">
                        <div className="chat-body common-form" style={{ paddingTop: "1rem", justifyContent: "center", alignItems: "center" }}>
                            <SelectFiled
                                keyField={"questionnaire"}
                                label={"Select Questionnaire"}
                                formik={formik}
                                readOnly={isDisabled}
                                options={questionnaireList}
                                onChange={handleSelectChange}
                                value={selectedQuestionnaire} 
                            />
                            <hr />
                            <QuestionnaireRenderer 
                                formik={formik} 
                                questionResponse={formik.values.questionResponse} 
                                parentIndex={textEditorKey} 
                                readOnly={!canEditSelectedQuestionnaire} 
                            />
                             
                        </div>

                        <div className="msg-footer">
                            <div className="btn-wrap" style={{ display: 'flex', justifyContent: "space-evenly", gap: "1rem" }}> 
                              <Button type="submit" isLoading={btnLoading} onClick={formik.handleSubmit} style={{background: 'linear-gradient(90deg, #4CAF50 0%, #388E3C 100%)'}} >Save</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </Modal.Body>
    </Modal>
  );
}