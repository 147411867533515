import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

import Header from '../components/common/header/header';
import Sidebar from '../components/common/sidebar/sidebar';

import Organization from '../components/Admin/Organization/OrganizationList';
import AddOrganization from '../components/Admin/Organization/AddOrganization';
import ProgramsList from '../components/Admin/Program/ProgramsList';
import CreateProgram from '../components/Admin/Program/CreateProgram';
import ProgramUsersListing from '../components/Admin/Program/ProgramUsersListing';
import ManageRole from '../components/Admin/Roles/manage-role';
import NotificationsList from '../components/Admin/Notifications/NotificationsList';
import CreateNotification from '../components/Admin/Notifications/CreateNotification';
import { CreateRole } from '../components/Admin/Roles/CreateRole';
import { useSelector } from 'react-redux';
import PatientsAssigned from '../components/apps/Patient/patients-assigned';
import PatientsDetails from '../components/apps/Patient/patients-detail';
import ManageGroup from '../components/apps/Threashold/manage-group';
import Create from '../components/Admin/Individuals/create';
import ListUsers from '../components/Admin/Individuals/list';
import ZoomCreate from '../components/Zoom/ZoomCreate';
import Dashboard from '../components/Admin/Dashboard/dashboard';
import UserProfile from '../components/Profile/user-profile';
import CreateSchedule from '../components/Admin/Schedule/CreateSchedule';
import ChangePassword from '../components/Profile/change-password';
import ThresholdPatientList from '../components/apps/Threashold/threshold-patient-list';
import HcnList from '../components/Admin/HcnAuthority/hcn-list';
import ScheduleList from '../components/Admin/ScheduleCategory/category-list';
import AddSchedule from '../components/Admin/ScheduleCategory/add-category';
import AddHcn from '../components/Admin/HcnAuthority/add-hcn';
import ClinicLocationList from '../components/Admin/Clinic Location/List';
import AddClinicLocation from '../components/Admin/Clinic Location/Add';
import EncounterSearch from '../components/apps/Encounters/EncounterSearch'
import AppointmentsList from '../components/apps/AppointmentRequests/AppointmentsList';
import UserNotificationsList from '../components/Admin/Notifications/UserNotificationsList';
import CalendarView from '../components/Admin/Schedule/CalendarView';
import ScheduleOverview from '../components/Admin/Schedule/ScheduleOverview';
import GroupList from '../components/Admin/Group/GroupList';
import AddGroup from '../components/Admin/Group/AddGroup';
import GroupUsersListing from '../components/Admin/Group/GroupUsersListing';
import ListOfPractitioner from '../components/apps/ManageProviderSchedule&Appointments/ListOfPractitioner';
import CreateQuestion from '../components/Admin/Questionnaire/CreateQuestion';
import QuestionsList from '../components/Admin/Questionnaire/QuestionsList';
import CreateQuestionnaire from '../components/Admin/Questionnaire/CreateQuestionnaire';
import QuestionnaireList from '../components/Admin/Questionnaire/QuestionnaireList';
import SurveysList from '../components/Admin/Surveys/SurveysList';
import CreateSurveys from '../components/Admin/Surveys/CreateSurveys';
import SurveysResultsList from '../components/Admin/Surveys/SurveysResultsList';
import CreateCategory from '../components/Admin/Questionnaire/CreateCategory';
import CategoryList from '../components/Admin/Questionnaire/CategoryList';
import ServiceRequest from '../components/apps/ServiceRequest/ServiceRequest';
import ViewServiceRequest from '../components/apps/ServiceRequest/ViewServiceRequest';
import ViewMedicationRequest from '../components/apps/MedicationRequest/ViewMedicationRequest';
import CreateMedicationRequest from '../components/apps/MedicationRequest/CreateMedicationRequest';
import QueueList from '../components/Admin/Queue/QueueList';
import SFTList from '../components/Admin/SFT/SFTList';
import EncounterDetail from '../components/apps/Patient/EncounterDetail';
import DocumentList from '../components/Admin/FillableDocuments/DocumentList';
import AddDocuments from '../components/Admin/FillableDocuments/AddDocuments';
import CreateCondition from '../components/apps/MedicalHistory.js/CreateCondition';
import CreateSurgeryScreening from '../components/apps/MedicalHistory.js/CreateSurgeryScreening';
import EditCondition from '../components/apps/MedicalHistory.js/EditConditions';
import EditSurgeryScreening from '../components/apps/MedicalHistory.js/EditSurgeryScreening';
import ZoomCreateVV from '../components/Zoom/ZoomCreateVV';
import CreateFamilyHistory from '../components/apps/MedicalHistory.js/CreateFamilyHistory';
import EditFamilyHistory from '../components/apps/MedicalHistory.js/EditFamilyHistory';
import CreateObservation from '../components/apps/MedicalHistory.js/CreateObservation';
import EditObservation from '../components/apps/MedicalHistory.js/EditObservation';
import { useGetOrgLicensedProducts } from '../hooks/ReactQueryHooks/useGetOrgLicensedProducts';
import PhoneCallCreate from '../components/Zoom/PhoneCallCreate';
import { useEffect, useState } from 'react';
import { getOrganization } from '../components/api/Organization';
import { failed } from '../components/common/Toastify';
import DocumentDashboard from '../components/Admin/DocumentDashboard/DocumentDashboard';
import UploadDocument from '../components/Admin/DocumentDashboard/UploadDocument';
import EditDocument from '../components/Admin/DocumentDashboard/EditDocument';
import { FullPageSpinner } from '../components/common/Spinner/FullPageSpinner';
import MFALanding from '../components/MFA/MFALanding';
import Queue from '../components/apps/Queue/Queue';
import EncounterReportWR from '../components/apps/Queue/EncounterReportWR';
import EditQuestionnaire from '../components/Admin/Questionnaire/EditQuestionnaire';
import TriageParentComponent from '../components/apps/Protocol/TriageParentComponent';
import EncounterReport from '../components/apps/Patient/EncounterReport';

const App = () => {
    return (
        <div className="outer-wrapper">
            <Header />
            <div className="body-outer">
                <Sidebar />
                <div className="body-wrapper">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export const NoPermission = () => {
    const navigate = useNavigate()
    return navigate("/app/dashboard");
};

export const useProtectedRoutes = () => {

    const permissions = useSelector((state) => state?.auth?.user?.permissions);
    const userRoles = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
    const adminOrgs = useSelector((state) => state?.auth?.user?.organizations)
    const admin = userRoles?.includes('Super-Admin');
    const practitioner = userRoles?.includes('Practitioner');
    const { isLoading, data: orgLicensedProducts } = useGetOrgLicensedProducts({ refetchInterval: 300000 })
    const isVirtualCareEnable = admin ? true : orgLicensedProducts?.virtualCare;
    const [isWaitingRoom, setIsWaitingRoom] = useState(null)
    const { pathname } = useLocation();

    useEffect(() => {
        if (pathname !== "/" && pathname !== "/login" && adminOrgs) {
            getOrganization(adminOrgs?.[0]?.id)
                .then((res) => {
                    setIsWaitingRoom(res?.data?.isWaitingRoom)
                })
                .catch((res) => {
                    if (pathname !== "/sft") {
                        failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                    }
                });
        }
    }, [])

    const protectedRoutes = [
        {
            path: '/app',
            element: <App />,
            children: [
                //Admin's Routes goes here
                //orgs
                { path: "add-organization", element: admin ? <AddOrganization /> : <NoPermission /> },
                { path: "manage-organization", element: permissions?.includes('Manage Organization') || admin ? <Organization /> : <NoPermission /> },
                //org's program
                { path: 'create-program', element: permissions?.includes('Manage Program') || admin ? <CreateProgram /> : <NoPermission /> },
                { path: "manage-program", element: permissions?.includes('Manage Program') || admin ? <ProgramsList /> : <NoPermission /> },
                { path: "program-patients-listing", element: (permissions?.includes('Manage Program')) || admin ? <ProgramUsersListing /> : <NoPermission /> },
                //org's Questionnaires
                { path: 'create-questions', element: permissions?.includes('Manage Questionnaire') || admin ? <CreateQuestion /> : <NoPermission /> },
                { path: 'manage-questions', element: permissions?.includes('Manage Questionnaire') || admin ? <QuestionsList /> : <NoPermission /> },
                { path: "create-questionnaire", element: permissions?.includes('Manage Questionnaire') || admin ? <CreateQuestionnaire /> : <NoPermission /> },
                { path: "edit-questionnaire", element: permissions?.includes('Manage Questionnaire') || admin ? <EditQuestionnaire /> : <NoPermission /> },
                { path: "manage-questionnaire", element: permissions?.includes('Manage Questionnaire') || admin ? <QuestionnaireList /> : <NoPermission /> },
                { path: "create-category", element: permissions?.includes('Manage Questionnaire') || admin ? <CreateCategory /> : <NoPermission /> },
                { path: "manage-category", element: permissions?.includes('Manage Questionnaire') || admin ? <CategoryList /> : <NoPermission /> },

                //org's roles
                { path: "create-role", element: permissions?.includes('Manage Roles') || admin ? <CreateRole /> : <NoPermission /> },
                { path: "manage-roles", element: permissions?.includes('Manage Roles') || admin ? <ManageRole /> : <NoPermission /> },
                //org's Individuals
                { path: "create-individual", element: permissions?.includes('Manage Individuals') || admin ? <Create /> : <NoPermission /> },
                { path: "manage-individuals", element: permissions?.includes('Manage Individuals') || admin ? <ListUsers /> : <NoPermission /> },
                { path: "service-request", element: true || admin ? < ServiceRequest /> : <NoPermission /> },
                { path: "view-service-request", element: true || admin ? <ViewServiceRequest /> : <NoPermission /> },
                { path: "medication-request", element: true || admin ? <CreateMedicationRequest /> : <NoPermission /> },
                { path: "view-medication-request", element: true || admin ? <ViewMedicationRequest /> : <NoPermission /> },
                { path: "create-condition", element: true || admin ? <CreateCondition /> : <NoPermission /> },
                { path: "surgeries-screening", element: true || admin ? <CreateSurgeryScreening /> : <NoPermission /> },
                { path: "create-familyHistory", element: true || admin ? <CreateFamilyHistory /> : <NoPermission /> },
                { path: "create-observation", element: true || admin ? <CreateObservation /> : <NoPermission /> },
                { path: "edit-condition", element: true || admin ? <EditCondition /> : <NoPermission /> },
                { path: "edit-surgeries-screening", element: true || admin ? <EditSurgeryScreening /> : <NoPermission /> },
                { path: "edit-familyHistory", element: true || admin ? <EditFamilyHistory /> : <NoPermission /> },
                { path: "edit-observation", element: true || admin ? <EditObservation /> : <NoPermission /> },
                //org's notifications
                { path: "CreateNotification", element: permissions?.includes('Manage Notifications') || admin ? <CreateNotification /> : <NoPermission /> },
                { path: "manage-notifications", element: permissions?.includes('Manage Notifications') || admin ? <NotificationsList /> : <NoPermission /> },
                //User's notifications
                { path: "user-notifications", element: <UserNotificationsList /> },
                { path: "zoom-meeting-create", element: <ZoomCreate /> },
                { path: "zoom-meeting-create-vv", element: <ZoomCreateVV /> },
                { path: "phone-call-create", element: <PhoneCallCreate /> },
                //HCN Authority Section starts here
                { path: "hcn-list", element: admin ? <HcnList /> : <NoPermission /> },
                { path: "add-hcn", element: admin ? <AddHcn /> : <NoPermission /> },
                //Schedule category for doctor available time slots
                { path: "manage-schedule-category", element: (permissions?.includes('Manage Schedule Category')) || admin ? <ScheduleList /> : <NoPermission /> },
                { path: "add-schedule", element: (permissions?.includes('Manage Schedule Category')) || admin ? <AddSchedule /> : <NoPermission /> },
                //Clinic location
                { path: "manage-clinic-locations", element: (permissions?.includes('Manage Locations')) || admin ? <ClinicLocationList /> : <NoPermission /> },
                { path: "add-clinic-location", element: (permissions?.includes('Manage Locations')) || admin ? <AddClinicLocation /> : <NoPermission /> },
                //Org's Group
                { path: "manage-groups", element: (permissions?.includes('Manage Groups')) || admin ? <GroupList /> : <NoPermission /> },
                { path: "add-group", element: (permissions?.includes('Manage Groups')) || admin ? <AddGroup /> : <NoPermission /> },
                { path: "group-patients-listing", element: (permissions?.includes('Manage Groups')) || admin ? <GroupUsersListing /> : <NoPermission /> },
                // fillable Documents
                { path: "documents", element: isLoading ? <FullPageSpinner /> : ((permissions?.includes('Manage Documents')) || admin && isVirtualCareEnable) ? <DocumentList /> : <Navigate to="/app/dashboard" /> },
                { path: "add-documents", element: isLoading ? <FullPageSpinner /> : ((permissions?.includes('Manage Documents')) || admin && isVirtualCareEnable) ? <AddDocuments /> : <Navigate to="/app/dashboard" /> },

                //App's Routes goes here
                //pract's assigned patients
                { path: "patients-assigned", element: permissions?.includes('Patients Assigned') ? <PatientsAssigned /> : <NoPermission /> },
                { path: "patients-details", element: permissions?.includes('Patients Assigned') || admin || practitioner ? <PatientsDetails /> : <NoPermission /> },
                { path: "encounter-details", element: permissions?.includes('Patients Assigned') || admin || practitioner ? <EncounterReport /> : <NoPermission /> },
                { path: "encounter-report", element: permissions?.includes('Patients Assigned') || admin || practitioner ? <EncounterReportWR /> : <NoPermission /> },
                //pract's threshold-groups for patients
                { path: "threshold-groups", element: permissions?.includes('Manage Threshold Groups') || admin || practitioner ? <ManageGroup /> : <NoPermission /> },
                { path: "threshold-patient-list", element: permissions?.includes('Manage Threshold Groups') || admin || practitioner ? <ThresholdPatientList /> : <NoPermission /> },
                //Dashboard
                { path: "dashboard", element: (permissions?.includes('Manage Individuals') && permissions?.includes('Manage Organization')) || admin || practitioner ? <Dashboard /> : <NoPermission /> },
                //change password
                { path: "change-password", element: <ChangePassword /> },
                { path: "user-profile", element: <UserProfile /> },
                //Manage pract's Schedule and appointment manage
                { path: "providers-schedule-appointments", element: permissions?.includes("Manage Provider Schedule & Appointments") || admin || practitioner ? <ListOfPractitioner /> : <NoPermission /> },
                //pract's Schedule
                { path: "create-schedule", element: <CreateSchedule /> },
                { path: "edit-schedule", element: <CreateSchedule /> },
                { path: "schedule", element: !admin ? <CalendarView /> : <NoPermission /> },
                // Schedule Overview
                { path: "schedule-overview", element: permissions?.includes("Manage Provider Schedule & Appointments") || admin ? <ScheduleOverview /> : <NoPermission /> },
                // Appointment Requests
                { path: "appointment", element: (permissions?.includes('Appointment')) || admin || practitioner ? <AppointmentsList /> : <NoPermission /> },
                { path: "encounter-search", element: <EncounterSearch />},
                // Protocol Assessment Route
                {path: "protocol-assessment", element: <TriageParentComponent />},
                // waiting room Section starts here
                // { path: "waiting-room", element: permissions?.includes("Waiting Room Enrollment") || permissions?.includes('Waiting Room Patients') ? <PatientListing canEnrollPatient={permissions?.includes("Waiting Room Enrollment")} canConnectWithPatient={permissions?.includes('Waiting Room Patients')} /> : <NoPermission /> },
                // { path: "join-waiting-room-meet", element: <JoinWaitingRoomMeet /> },
                // { path: "enroll-patient", element: permissions?.includes('Waiting Room Enrollment') ? <EnrollPatient /> : <NoPermission /> },
                //org's Surveys
                { path: "create-surveys", element: <CreateSurveys /> },
                { path: "manage-surveys", element: <SurveysList /> },
                { path: "manage-surveys-results", element: permissions?.includes('Satisfaction Survey Results') || admin ? <SurveysResultsList /> : <NoPermission /> },

                // Queue Service
                { path: "manage-queue", element: !admin ? isWaitingRoom ? (permissions?.includes('Manage Groups') || admin) ? <QueueList /> : <NoPermission /> : <NoPermission /> : <NoPermission /> },
                { path: "waiting-room", element: <Queue /> },
                // SFT Service
                { path: "manage-sft", element: (permissions?.includes('Manage SFT')) || admin ? <SFTList /> : <NoPermission /> },

                //Dashborad Routes
                { path: "document-dashboard", element: isLoading ? <FullPageSpinner /> : (isVirtualCareEnable && permissions?.includes("Access Document Dashboard") && practitioner) ? <DocumentDashboard /> : <Navigate to="/app/dashboard" /> },
                { path: "upload-document", element: isLoading ? <FullPageSpinner /> : (isVirtualCareEnable && permissions?.includes("Upload Documents") && permissions?.includes("Access Document Dashboard") && practitioner) ? <UploadDocument /> : <Navigate to="/app/dashboard" /> },
                { path: "edit-document", element: isLoading ? <FullPageSpinner /> : (isVirtualCareEnable && practitioner) ? <EditDocument /> : <Navigate to="/app/dashboard" /> },

                { path: "/app", element: <NoPermission /> },

                //MFA Settings
                { path: "mfa-settings", element: <MFALanding /> }
            ]
        },
        { path: '/*', element: <Navigate to="/app/dashboard" /> },
    ];

    return { protectedRoutes };
} 
