import { differenceInYears, differenceInMonths, differenceInWeeks, differenceInDays, sub } from 'date-fns'
import moment from 'moment'
/**
 * ageCalc
 * generates an age string with the following algorithm:
 * 
 * Age calculation should default to the formula below (no rounding):
 * 
 * --age < 7 days to show # of days
 * --age 7 days to 90 days to show # weeks and #days
 * --age 90 days to 1 year shows # months 
 * --age 1 year to 2 years show # years and # months
 * --age >= 2 years shows # years
 * 
 * @param dob as Date 
 * @param date as Date
 * @param threshold as JSON  a json object with thresholds, which works as follows:
 * 	yrs is the maximum number of years where a partial years must be shown (so years and number of months) anything greater shows only years
 * 	mths is the number of days where only months are displayed
 *  wks is the number of days where weeks + days must be shown anything lower will only show days
 *  all properties must indicate smaller units of time. IN THE MAJORITY OF CASES the default will be used
 * @returns json object of descrete values
 * 
 * @exception Invalid Date Range Exception  if the dob value is later than the date value resulting in negative values.
 * @exception Incomplete Date Range Exception if one of the incoming dates is null/undefined 
 */
export function ageCalc(dob, date, threshold = {yrs: 1, mths: 90, wks: 7 }) {
	
	let years = 0, months = 0, weeks = 0, days = 0;
	let ageUnits = {};
	

	if(dob && date) {

		if(dob > date) {
			// this would result in a negative age
			throw new Error("Invalid date range");
		}
		else {
			years = differenceInYears(date, dob);
			
			if(years === threshold.yrs) 
				months = differenceInMonths(sub(date, {years:years}), dob);
			
			if(years < threshold.yrs) {
				days = differenceInDays(date, dob);
				if(days >= threshold.mths) {
					months = differenceInMonths(date, dob);
					days = differenceInDays(sub(date, {years:years, months:months}),dob);
				}
				else if(days >= threshold.wks) {
					weeks = differenceInWeeks(date, dob);
					days = differenceInDays(sub(date, {years:years, weeks:weeks}),dob);
				}
			}
			ageUnits["year"] = years;
			ageUnits["month"] = months;
			ageUnits["week"] = weeks;
			ageUnits["day"] = days;

			return ageUnits;
		}
	}
	// if either dob or date is null/undefined age cannot be calculated because it is incomplete.
	throw new Error("Incomplete date range.");
};

/**
 * 
 * @param dob string or date object
 * @param date string or date object, defaults to now but could be any date in time.
 * @param locale string is any 2 char string language (so 'fr', 'es', 'de', 'it' etc)
 * refer to this: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl for info on the internationalization
 * @returns a formatted string indicating years, months, weeks, days in the locale, defaulting to English, if a value is 0 it is 
 * not displayed, so for example: 
 * {
 * 		year: 1,
 * 		month: 2,
 * 		week: 0,
 * 		day: 0
 * }
 * will return "1 years, 2 months" and omit any 0 values separated by commas.
 */
export function ageString(dob, date, locale) {
	let ageString = "";
	try {
		let units = ageCalc(dob, date); // get the date units as per the required algorithm
	
		for(let prop in units) {
			if(units[prop] > 0) {
				if(ageString.length > 0) 
					ageString += ", ";
				
				const parts = new Intl.RelativeTimeFormat(locale, { numeric: 'auto' }).formatToParts(units[prop], prop);					
				try {
					if(parts.length !== 3) { 
						// this is required to handle some local colloquialism, for example, 1 and day in english returns a single part 
						// with "Tomorrow" as the value instead of "1 day"
						// if prop is "week" it needs to be changed to "weekOfYear"
						ageString += `${units[prop]} ${new Intl.DisplayNames(locale, { type: 'dateTimeField' }).of((prop === "week")? "weekOfYear" : prop)}`;
					}
					else {
						ageString += `${parts[1].value}${parts[2].value}`;
					}
				}
				catch(e) {
					ageString += `${units[prop]} ${prop}`;
				}
			}
		}
	}
	catch(e) {
		// TODO, use locale to translate Invalid or Unknown into appropriate language.
		// see FIORIZEL-71 ticket https://fonemed.atlassian.net/browse/FIORIZEL-71
		if(e.message.startsWith("Invalid")) 
			ageString = "Invalid";
		else 
			ageString = "-";
	}
	return ageString;
};

/**
 * 
 * @param dob as Date or String
 * @param date as Date or String (if not provided defaults to current date) 
 * @param locale as String a 2 char language string, defaults to 'en'
 * @returns 
 */
export function safeAgeString(dob, date = new Date(), locale = 'en') {
	dob = dateifier(dob);
	date = dateifier(date);
	let ageValue = ageString(dob, date, locale);
	if(ageValue === null || ageValue === "") ageValue = 0 + " " + "days"
	return ageValue
};

export function calculateAgeCode(age) {
	switch (age) {
		case "minutes": return "min";
		case "minute": return "min";
		case "hours": return "h";
		case "hour": return "h";
		case "days": return "d";
		case "day": return "d";
		case "weeks": return "wk";
		case "week": return "wk";
		case "months": return "mo";
		case "month": return "mo";
		case "year": return "a";
		case "years": return "a";
		default: break;
	}
};

export function calculateAgeUnit(age) {
	switch (age) {
		case "minutes": return "min";
		case "minute": return "min";
		case "hours": return "hr";
		case "hour": return "hr";
		case "days": return "day";
		case "day": return "day";
		case "weeks": return "week";
		case "week": return "week";
		case "months": return "month";
		case "month": return "month";
		case "year": return "yr";
		case "years": return "yr";
		default: break;
	}
};

/**
 * 
 * @param dateString as String or Date
 * @return Date or null
 */
export function dateifier(date = null) {

	if(typeof date === "string") {
		date = new Date(date);
	}
	
	if(date instanceof Date && date.toString() !== "Invalid Date") 
		return date;
	
	return null;
};

/**
 * Message that has utc time will have the time converted to local time of the user
 *
 * @param {string} message - Originally the Notification message
 * @returns {string} The message is returned with utc time converted to local time(if present)
 */
export function withLocalTime(message) {
    // Regex to match the date and time in the format like 'NOV-29-2024 03:30 PM'
    let pattern = /\b[A-Z]{3}-\d{2}-\d{4} \d{2}:\d{2} [APM]{2}\b/;
    let matches = message?.match(pattern);
    
    if (matches) {
        // Parse the matched date string as UTC and convert it to local time
        let utcDate = moment.utc(matches[0], 'MMM-DD-YYYY hh:mm A');

        if (!utcDate.isValid()) {
            console.error("Invalid date format in message:", matches[0]);
            return message; // Return original message if parsing fails
        }

        let localTime = utcDate.local().format('MMM-DD-YYYY hh:mm A z');
        message = message.replace(pattern, localTime);
    }
    
    return message;
}

export const ViewDate = (date)=>{
	const Date = moment(date).format("MMM-DD-YYYY").toUpperCase();
	if(Date === "INVALID DATE") return "-";
    return Date;
}

export const ViewDateTime = (date)=>{
    return moment(date).format("MMM-DD-YYYY | HH:mm").toUpperCase();
}

export const ViewTime = (date)=>{
	const Time = moment(date).format("HH:mm").toUpperCase();
	if(Time === "INVALID DATE") return "-";
    return Time;
}