import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import TextInput from "../../common/textfield/TextInput";
import * as Yup from "yup";
import {
  createAccountAPI,
  getPatientInfo,
  getSingleUser,
  updatePatientDemographics,
} from "../../api/Individual";
import SelectFiled from "../../common/textfield/SelectFiled";
import ReactDatePicker from "../../common/textfield/ReactDatePicker";
import {
  caregions,
  createAccountOptions,
  genderOptions,
  usregions,
} from "./Constants";
import CountrySelect from "../../common/textfield/CountrySelect";
import RegionSelect from "../../common/textfield/RegionSelect";
import { Button } from "../../common/Button";
import UpdateDetailsIcon from "../../../assets/images/update.svg";
import { ViewDate } from "../../../utils/DateSupport";
import { splitContactNumber } from "../../../utils/phoneNumberSupport";
import { decryptData } from "../../EncryptDecrypt";
import { useSelector } from "react-redux";
import { failed, success } from "../../common/Toastify";
import Switch from "@mui/material/Switch";
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";
import { commonDisableAPI } from "../../api/CommonApis";

const EditPatientModal = ({
  patientId,
  onShow,
  onHide,
  hasAccessToEdit,
  organizations,
}) => {
  const [patientRecord, setPatientRecord] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const myOrgId = useSelector(
    (state) => state?.auth?.user?.organizations?.[0]?.id
  );
  const [personRecord, setPersonRecord] = useState(null);
  const { getConfirmation } = useConfirmDialog();
  const [createAccountFlag, setCreateAccountFlag] = useState(false);
  const [callUsersApi, setCallUsersApi] = useState(false);

  const formik = useFormik({
    initialValues: {
      first_name: patientRecord?.name?.[0]?.given?.[0] || "",
      last_name: patientRecord?.name?.[0]?.family || "",
      date_of_birth: patientRecord?.birthDate || "",
      phone_number: "",
      ext: "",
      address: patientRecord?.address?.[0]?.line?.[0] || "",
      city: patientRecord?.address?.[0]?.city || "",
      country: patientRecord?.address?.[0]?.country || "",
      region: patientRecord?.address?.[0]?.state || "",
      email: "",
      organization:
        patientRecord?.managingOrganization?.[0]?.reference?.split("/")?.[1] ||
        "",
      gender: patientRecord?.gender || "",
      postalCode: patientRecord?.address?.[0]?.postalCode || "",
      create_account: patientRecord?.accountCreated || "",
      organization_display: patientRecord?.managingOrganization?.[0]?.display,
      hcn:
        patientRecord?.identifier?.find(
          (identifier) => identifier?.type === "HCN"
        )?.value || "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string()
        .max(50, "Must be 50 characters or less")
        .required("Required first name field")
        .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
      last_name: Yup.string()
        .max(50, "Must be 50 characters or less")
        .required("Required last name field")
        .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
      date_of_birth: Yup.string().required("Required date of birth field"),
      phone_number: Yup.string().matches(
        /^(?:\+?1[-. ]?)?\(?[0-9]\d{2}\)?[-. ]?\d{3}[-. ]?\d{4}$/,
        "Enter Valid Phone Number."
      ),
      gender: Yup.string()
        .required("Required gender field")
        .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
      ext: Yup.string().matches(/^\d{0,4}$/, "Must be 4 digit or less."),
      address: Yup.string().matches(
        /^$|^\S+.*/,
        "Only blankspaces is not valid."
      ),
      city: Yup.string()
        .required("Required city field")
        .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
      email:
        (patientRecord?.accountCreated === "false" ||
          patientRecord?.accountCreated === false) &&
        (createAccountFlag === "true" || createAccountFlag === true) &&
        Yup.string()
          .email("Invalid email address")
          .required("Required email field")
          .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
      country: Yup.string()
        .required("Required country field")
        .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
      region: Yup.string()
        .required("Required region field")
        .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
      postalCode: Yup.string().when(["country"], (country) =>
        country === "US"
          ? Yup.number()
              .typeError("Zip code must be a number")
              .required("Required field")
          : Yup.string()
              .required("Required field")
              .matches(
                /^[A-Za-z]\d[A-Za-z] [0-9][A-Za-z]\d$/,
                "Postal code must be in A1A 1A1 format."
              )
      ),
      hcn: Yup.string()
        .matches(/^\d+$/, "HCN must contain only numbers")
        .required("HCN is required"),
    }),
    onSubmit: (values) => {
      setBtnLoading(true);
      let payload = {
        birthDate: values?.date_of_birth,
        firstName: values?.first_name,
        lastName: values?.last_name,
        gender: values?.gender,
        managingOrganization:
          values?.organization?.[0]?.reference?.split("/")[1],
        phone: values?.phone_number,
        address: [
          {
            city: values?.city,
            country: values?.country,
            postalCode: values?.postalCode,
            state: values?.country + "-" + values?.region,
            use: "home",
            line: [`${values?.address}`],
          },
        ],
      };

      if (values?.ext) {
        payload.phone = values?.phone_number + " ext. " + values?.ext;
      }

      let payloadForUsers = {
        name: [
          {
            use: "usual",
            text: values?.first_name + " " + values?.last_name,
            family: values?.last_name,
            given: [values?.first_name],
          },
        ],
        gender: values?.gender,
        birthDate: values?.date_of_birth,
        telecom: [
          {
            system: "email",
            value: values?.email,
            use: "work",
          },
        ],
        address: [
          {
            city: values?.city,
            country: values?.country,
            postalCode: values?.postalCode,
            state: values?.country + "-" + values?.region,
            use: "home",
            line: [`${values?.address}`],
          },
        ],
        email: values?.email,
        managingOrganization: values?.organization,
        generalPractitioner: [],
        type: ["Patient"],
        healthCardNumber: values?.hcn,
        id: personRecord?.id,
        createAccount: true,
      };

      if (values?.phone_number) {
        payloadForUsers.telecom.push({
          systemm: "phone",
          use: "work",
          value: values?.phone_number,
        });
      }

      if (callUsersApi) {
        createAccountAPI(myOrgId, payloadForUsers).then((res) => {
          if (res?.status === true) {
            updatePatientDemographics(patientId, myOrgId, payload)
              .then((res) => {
                if (res?.status === true) {
                  success(res?.message);
                  onHide();
                }
              })
              .catch((res) => failed(res?.message || res?.result?.message))
              .finally(() => setBtnLoading(false));
          }
        });
      } else {
        updatePatientDemographics(patientId, myOrgId, payload)
          .then((res) => {
            if (res?.status === true) {
              success(res?.message);
              onHide();
            }
          })
          .catch((res) => failed(res?.message || res?.result?.message))
          .finally(() => setBtnLoading(false));
      }
    },
  });

  useEffect(() => {
    if (patientId) {
      getPatientInfo(patientId).then((res) => {
        if (res?.result) {
          getSingleUser({
            id: patientId,
            type: "Patient",
            orgId: myOrgId,
          }).then((res) => {
            if (res?.status === true && res?.data) {
              setPersonRecord(res?.data);
            }
          });
          if (res?.result?.accountCreated) {
            setCreateAccountFlag(true);
          }
          let countryRegion = res?.result?.address?.[0]?.state?.split("-");
          setPatientRecord(res?.result);
          formik.setFieldValue(
            "first_name",
            res?.result?.name?.[0]?.given?.[0]
          );
          formik.setFieldValue("last_name", res?.result?.name?.[0]?.family);
          formik.setFieldValue("date_of_birth", res?.result?.birthDate);
          formik.setFieldValue(
            "hcn",
            res?.result?.identifier?.find(
              (identifier) => identifier?.type === "HCN"
            )?.value || ""
          );
          formik.setFieldValue("gender", res?.result?.gender);
          formik.setFieldValue("address", res?.result?.address?.[0]?.line?.[0]);
          formik.setFieldValue("country", res?.result?.address?.[0]?.country);
          formik.setFieldValue("region", countryRegion?.[1]);
          formik.setFieldValue("city", res?.result?.address?.[0]?.city);
          formik.setFieldValue(
            "postalCode",
            res?.result?.address?.[0]?.postalCode
          );
          formik.setFieldValue(
            "phone_number",
            splitContactNumber(
              decryptData(
                res?.result?.telecom?.find((ele) => ele?.system === "phone")
                  ?.value || ""
              )
            )?.phone_number?.replace(/^\+1/, "")
          );
          formik.setFieldValue(
            "email",
            res?.result?.telecom?.find((num) => num?.system === "email")?.value
          );
          formik.setFieldValue(
            "organization",
            res?.result?.managingOrganization
          );
          formik.setFieldValue(
            "organization_display",
            res?.result?.managingOrganization?.[0]?.display
          );
          formik.setFieldValue("create_account", res?.result?.accountCreated);
          formik.setFieldValue(
            "ext",
            splitContactNumber(
              decryptData(
                res?.result?.telecom?.find((ele) => ele?.system === "phone")
                  ?.value || ""
              )
            )?.ext
          );
        }
      });
    }
  }, [patientId]);

  const handlePostalCodeChange = (e) => {
    const { value } = e?.target;
    let spacedValue = value;
    if (formik?.values?.country !== "US") {
      // Remove spaces and non-alphanumeric characters
      const formattedValue = value.replace(/[^A-Za-z0-9]/g, "");
      // Add a space after every 3 letters
      spacedValue = formattedValue.replace(
        /^([A-Za-z0-9]{3})([A-Za-z0-9]+)/,
        "$1 $2"
      );
    }
    // Set the formatted value in the formik
    formik?.setFieldValue("postalCode", spacedValue);
  };

  const handleOrgChange = (event) => {
    formik.setFieldValue("organization", event?.target?.value);
  };

  const handleCreateAccountChange = (e) => {
    formik?.setFieldValue("create_account", e?.target?.value);
    setCreateAccountFlag(e?.target?.value);
    if (e?.target?.value === "true") {
      setCallUsersApi(true);
    } else {
      setCallUsersApi(false);
    }
  };

  const handleEnableClick = async (id, entryName) => {
    const status = "enable";
    const confirmed = await getConfirmation({
      title: "Attention!",
      entryName,
      actionBtnTitle: "Activate",
      message: "Are you sure you would like to activate ",
    });
    if (confirmed) {
      setBtnLoading(true);
      commonDisableAPI({
        key: "person",
        id,
        status,
        userOrgId: myOrgId,
      })
        .then(() => {
          success("Activated Individual!");
          onHide();
        })
        .catch((res) =>
          failed(
            res?.response?.data?.message ||
              res?.response?.data?.error ||
              res.message
          )
        )
        .finally(() => setBtnLoading(false));
    }
  };

  const handleDelete = async (id, entryName) => {
    const body = {
      key: "person",
      id,
      userOrgId: myOrgId,
    };

    const confirmed = await getConfirmation({
      title: "Attention!",
      entryName,
      actionBtnTitle: "Deactivate",
      message: "Are you sure you would like to deactivate ",
    });

    if (confirmed) {
      setBtnLoading(true);
      commonDisableAPI(body)
        .then(() => {
          success("Individual Deactivated!");
          onHide();
        })
        .catch((res) =>
          failed(
            res?.response?.data?.message ||
              res?.response?.data?.error ||
              res?.message
          )
        )
        .finally(() => {
          setBtnLoading(false);
        });
    }
  };

  const handleSwitch = () => {
    personRecord?.active
      ? handleDelete(personRecord?.id, patientRecord.name?.[0]?.text)
      : handleEnableClick(personRecord?.id, patientRecord.name?.[0]?.text);
  };

  return (
    <Modal
      backdropClassName
      backdrop={"static"}
      show={onShow}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="custom-dialog"
    >
      <Modal.Header closeButton className="border-0">
        <Modal.Title id="contained-modal-title-vcenter">
          Edit Patient Details
        </Modal.Title>
        {personRecord && patientRecord?.accountCreated === true && (
          <div className="">
            <Switch
              checked={personRecord?.active}
              onChange={() => handleSwitch()} // Use the callback function
              color="primary"
            />
          </div>
        )}
      </Modal.Header>
      <Modal.Body>
        <form className="common-form border-fields userAccount">
          <Row>
            <Col>
              <TextInput
                style={{
                  textTransform: "capitalize",
                }}
                type={"text"}
                keyField={"first_name"}
                label={"First Name"}
                formik={formik}
                placeholder={"First Name"}
                required={true}
                readOnly={!hasAccessToEdit}
              />
            </Col>
            <Col>
              <TextInput
                style={{
                  textTransform: "capitalize",
                }}
                type={"text"}
                keyField={"last_name"}
                label={"Last Name"}
                formik={formik}
                placeholder={"Last Name"}
                required={true}
                readOnly={!hasAccessToEdit}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ReactDatePicker
                keyField={"date_of_birth"}
                label={"Date of Birth"}
                formik={formik}
                placeholder={"Select Date"}
                hasValue={formik?.values?.["date_of_birth"]}
                displayAge={formik.errors.date_of_birth ? false : true}
              />
            </Col>
            <Col>
              <SelectFiled
                keyField={"gender"}
                label={"Select Gender"}
                formik={formik}
                options={genderOptions}
              />
            </Col>
            <Col>
              <TextInput
                style={{
                  textTransform: "capitalize",
                }}
                type={"text"}
                keyField={"hcn"}
                label={"HCN"}
                formik={formik}
                placeholder={"HCN"}
                required={true}
                readOnly={!hasAccessToEdit}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextInput
                style={{
                  textTransform: "capitalize",
                }}
                type={"address"}
                keyField={"address"}
                label={"Address"}
                formik={formik}
                placeholder={"Address"}
                required={true}
                readOnly={!hasAccessToEdit}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <CountrySelect
                keyField={"country"}
                label={"Country"}
                formik={formik}
                readOnly={!hasAccessToEdit}
              />
            </Col>

            <Col>
              <RegionSelect
                defaultOptionLabel={
                  formik?.values?.country === "US"
                    ? "Select State"
                    : "Select Province"
                }
                keyField={"region"}
                label={formik?.values?.country === "US" ? "State" : "Province"}
                formik={formik}
                readOnly={!hasAccessToEdit}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextInput
                style={{
                  textTransform: "capitalize",
                }}
                type={"text"}
                keyField={"city"}
                label={"City"}
                formik={formik}
                placeholder={"City"}
                required={true}
                readOnly={!hasAccessToEdit}
              />
            </Col>
            <Col>
              <TextInput
                onChange={handlePostalCodeChange}
                keyField={"postalCode"}
                label={
                  formik?.values?.country === "US" ? "Zip Code" : "Postal Code"
                }
                formik={formik}
                placeholder={
                  formik?.values?.country === "US" ? "Zip Code" : "Postal Code"
                }
                readOnly={!hasAccessToEdit}
                // max length for both zip code and postal code(plus space)
                maxLength={formik?.values?.country === "US" ? "5" : "7"}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextInput
                style={{
                  textTransform: "capitalize",
                }}
                type={"phone_number"}
                keyField={"phone_number"}
                label={"Phone Number"}
                formik={formik}
                placeholder={"Phone Number"}
                required={false}
                readOnly={!hasAccessToEdit}
                hideRequired={true}
              />
            </Col>
            <Col>
              <TextInput
                style={{
                  textTransform: "capitalize",
                }}
                type={"phone_number"}
                keyField={"ext"}
                label={"Ext."}
                formik={formik}
                placeholder={"Ext."}
                required={false}
                readOnly={!hasAccessToEdit}
                hideRequired={true}
              />
            </Col>
            <Col>
              <TextInput
                type={"email"}
                keyField={"email"}
                label={"Email Address"}
                formik={formik}
                placeholder={"Email Address"}
                required={
                  formik?.values?.create_account === "false" ||
                  formik?.values?.create_account === false
                    ? false
                    : true
                }
                readOnly={
                  patientRecord?.telecom?.find((num) => num?.system === "email")
                    ?.value
                    ? true
                    : false
                }
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <TextInput
                style={{
                  textTransform: "capitalize",
                }}
                type={"text"}
                keyField={"organization_display"}
                label={"Organization"}
                formik={formik}
                placeholder={"Organization"}
                required={true}
                readOnly={true}
                disabled={true}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SelectFiled
                keyField={"create_account"}
                label={"Create Account"}
                formik={formik}
                placeholder={"Create Account"}
                options={createAccountOptions}
                readOnly={patientRecord?.accountCreated}
                onChange={handleCreateAccountChange}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Button
                style={{ marginRight: "20px" }}
                onClick={() => {
                  onHide();
                }}
                variant="secondary"
                title="Cancel"
              >
                Cancel
              </Button>
              <Button isLoading={btnLoading} onClick={formik.handleSubmit}>
                <img src={UpdateDetailsIcon} /> Update
              </Button>
            </Col>

            <Col style={{ display: "flex", justifyContent: "flex-end" }}>
              <div style={{ alignSelf: "flex-end" }}>
                User Created on {ViewDate(patientRecord?.createdAt)}
              </div>
            </Col>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default EditPatientModal;
