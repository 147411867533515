import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import TextArea from "../../common/textfield/TextArea";
import { useFormik } from "formik";
import { Button } from "../../common/Button";
import Row from "react-bootstrap/Row";
import * as Yup from "yup";
import Col from "react-bootstrap/Col";

const DispositionOverrideModal = ({
  currentSelectedDisposition,
  protocolData,
  onShow,
  onHide,
  handleOverrideSubmit,
}) => {
  const [availableDispositionOptions, setAvailableDispositionOptions] =
    useState([]);
  const [selectedDisposition, setSelectedDisposition] = useState(null); // Track dropdown selection
  const [dispositionLevel, setDispositionLevel] = useState(null);

  useEffect(() => {
    if (protocolData?.questions) {
      const grouped = protocolData.questions.reduce((acc, question) => {
        const { dispositionHeading, dispositionLevel } = question;

        if (!acc[dispositionHeading]) {
          acc[dispositionHeading] = { questions: [], dispositionLevel };
        }

        acc[dispositionHeading].questions.push(question);

        return acc;
      }, {});

      // Sort questions within each group
      for (const group in grouped) {
        grouped[group].questions.sort(
          (a, b) => a.questionOrder - b.questionOrder
        );
      }

      // Sort groups by dispositionLevel
      const sortedGrouped = Object.keys(grouped)
        .sort(
          (a, b) => grouped[b].dispositionLevel - grouped[a].dispositionLevel
        )
        .reduce((acc, group) => {
          acc[group] = grouped[group];
          return acc;
        }, {});

      // Convert sorted groups to options for the dropdown
      const options = Object.keys(sortedGrouped).map((key) => {
        const group = sortedGrouped[key];
        console.log(group);
        return {
          label: key, 
          value: key,
          level: group.dispositionLevel, 
        };
      });

      setAvailableDispositionOptions(options); // Update the state
    }
  }, [protocolData]);

  const formik = useFormik({
    initialValues: {
      rationale: "",
    },
    validationSchema: Yup.object({
      rationale: Yup.string().required("Required field"),
    }),
    onSubmit: (values) => {
      // Call the parent submit handler with selected data
      handleOverrideSubmit({
        selectedDisposition,
        rationale: values.rationale,
        dispositionLevel,
      });
      onHide(); // Close the modal
    },
  });

  return (
    <Modal
      backdrop="static"
      show={onShow}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="custom-dialog"
    >
      <Modal.Header closeButton className="border-0">
        <span className="header-text">Current Disposition:</span>&nbsp;&nbsp;
        <Modal.Title>{currentSelectedDisposition}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          className="common-form border-fields"
          onSubmit={formik.handleSubmit}
        >
          <Row>
            <Col>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                <span className="label" style={{ marginRight: "10px" }}>
                  Override To:
                </span>
                <Select
                  onChange={(option) => {
                    setSelectedDisposition(option?.value || null);
                    setDispositionLevel(option?.level || null);
                  }}
                  isMulti={false}
                  name="disposition-dropdown"
                  options={availableDispositionOptions}
                  classNamePrefix="select"
                  isClearable={true}
                  styles={{
                    container: (provided) => ({ ...provided, flex: 1 }),
                  }}
                  placeholder="Select Disposition..."
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div>
                <TextArea
                  keyField="rationale"
                  name="rationale"
                  label="Rationale"
                  value={formik.values.rationale}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  hideRequired={false}
                  required={true}
                  isError={formik.errors.rationale}
                />
              </div>
            </Col>
          </Row>

          <div className="btn-wrap" style={{ display: "flex", gap: "16px" }}>
            <Button variant="primary" type="submit">
              Submit
            </Button>
            <Button onClick={onHide} variant="secondary">
              Cancel
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default DispositionOverrideModal;
