import { useState } from "react";

const ExpandableContent = ({ content, maxLength = 350 }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  if (!content) return null;

  const isTruncated = content.length > maxLength; // Check if truncation is needed
  const displayContent = isExpanded
    ? content
    : isTruncated
    ? `${content.slice(0, maxLength)}...`
    : content; // Show full content if it's less than maxLength

  return (
    <div style={{fontSize: "16px"}}>
      <div dangerouslySetInnerHTML={{ __html: displayContent }} />
      {isTruncated && (
        <button
          onClick={handleToggle}
          className="see-more-button"
          style={{
            background: "none",
            border: "none",
            color: "blue",
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          {isExpanded ? "See Less" : "See More"}
        </button>
      )}
    </div>
  );
};

export default ExpandableContent;
