import React, { useEffect, useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import moment from "moment";
import { Button } from "../../common/Button";
import { failed, success } from "../../common/Toastify";
import { useSelector } from "react-redux";
import { editEncounter } from "../api/AppointmentRequests";
import { getSingleUser } from "../../api/Individual";
import { getAppointmentRecord } from "../api/AppointmentRequests";
import { getSignature } from "../../Zoom/functions";
import { useNavigate } from "react-router-dom";
import Audio from "../../../assets/images/Audio.svg";
import Video from "../../../assets/images/Video.svg";
import ConfirmationModal from "../../common/ConfirmationModal";
import { axios } from "../../../lib/axios";

export const ViewEncounterModal = ({ data, onShow, onHide, refreshEncounters }) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [patientDetails, setPatientDetails] = useState({});
  const practitionerName = useSelector((state) => state?.auth?.user?.name[0]?.text);
  const patientId = data?.subject?.reference?.split("/")[1];
  const [patientHCN, setPatientHCN] = useState("");
  const navigate = useNavigate();
  const practitionerId = useSelector((state) => state?.auth?.user?.["custom:practitioner_id"]);
  const [confirmModalShow, setConfirmModalShow] = useState(false);

  useEffect(() => {
    if (data?.subject) {
      setPatientDetails({
        birthDate: data?.subject?.birthDate || "-",
        gender: data?.subject?.gender || "-",
        name: data?.subject?.display || "-",
      });
    }
  }, [data]);

  useEffect(() => {
    if (patientId) {
      getSingleUser({
        id: patientId,
        type: "Patient",
      })
        .then((res) => {
          setPatientDetails(res.data);
          const hcnValue =
            res.data?.patient_identifier?.find((ele) => ele.type === "HCN")
              ?.value || "-";
          setPatientHCN(hcnValue);
        })
        .catch((res) => {
          failed(
            res?.response?.data?.message ||
            res?.response?.data?.error ||
            res.message
          );
        });
    }
  }, [patientId]);

  const handleCall = async (data) => {
    try {
      setBtnLoading(true);
      const encounterId = data?.id || null;

      // Use the current user as the actor
      const actor = {
        reference: `Practitioner/${practitionerId}`,
        display: practitionerName,
        type: "Practitioner",
      };

      // Patient comes from data.subject
      const patient = data?.subject;

      const updatedIntakeQuestions = {
        ...data?.intakeQuestions,
        intakeAssignedPractitioner: {
          individual: {
            display: practitionerName,
            reference: `Practitioner/${practitionerId}`,
            type: "Practitioner",
          },
          period: { start: new Date().toISOString(), end: null },
        },
      };

      const requestedPeriod = [
        {
          start: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
          end: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
        },
      ];

      await axios.put(`encounter/${encounterId}`, {
        status: "in-progress-appointment",
        requestedPeriod,
        actor,
        active: true,
        class: "NULL",
        intakeQuestions: updatedIntakeQuestions,
      });

      navigate("/app/phone-call-create", {
        state: {
          practitionerName,
          practitionerId,
          patientId: patient?.reference?.split("/")?.[1],
          patientName: patient?.display,
          encounterId,
          appointmentData: data,
          currentUser: { patient },
          questionnairInfo: data?.intakeQuestions,
          previousStatus: data?.status,
        },
      });
      success("Call started successfully!");
    } catch (error) {
      failed(error?.response?.data?.message || error.message);
    } finally {
      setBtnLoading(false);
    }
  };

  const handleVideoCall = async (data) => {
    try {
      setBtnLoading(true);

      const appointmentRecord = await getAppointmentRecord({
        appointmentId: data?.appointmentId,
      });
      const sessionToken = appointmentRecord?.data?.sessionToken;
      const intakeQuestions = appointmentRecord?.data?.intakeQuestions;

      if (sessionToken) {
        const base64Url = sessionToken.split(".")[1];
        const payloadinit = atob(base64Url.replace(/-/g, "+").replace(/_/g, "/"));
        const parseddata = JSON.parse(payloadinit || "{}");
        const topic = parseddata?.tpc.trim();
        const password = parseddata?.password;

        // Use the current user as the actor
        const actor = {
          reference: `Practitioner/${practitionerId}`,
          display: practitionerName,
          type: "Practitioner",
        };

        // Patient comes from data.subject
        const patient = data?.subject;
        const encounterId = data?.id;

        const updatedIntakeQuestions = {
          ...intakeQuestions,
          intakeAssignedPractitioner: {
            individual: {
              display: practitionerName,
              reference: `Practitioner/${practitionerId}`,
              type: "Practitioner",
            },
            period: { start: new Date().toISOString(), end: null },
          },
        };

        const requestedPeriod = [
          {
            start: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
            end: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
          },
        ];

        await axios.put(`encounter/${encounterId}`, {
          status: "in-progress-appointment",
          requestedPeriod,
          actor,
          active: true,
          class: "NULL",
          intakeQuestions: updatedIntakeQuestions,
        });

        const signatureResponse = await getSignature({ topic, role: 1, password });
        navigate("/app/zoom-meeting-create", {
          state: {
            encounterId,
            appointmentData: appointmentRecord?.data,
            topic,
            sessionToken: signatureResponse?.data?.sessionToken,
            password,
            patientName: patient?.display,
            questionnairInfo: intakeQuestions,
          },
        });
        success("Connected successfully!");
      } else {
        failed("Session token does not exist!");
      }
    } catch (error) {
      failed(error?.response?.data?.message || error.message);
    } finally {
      setBtnLoading(false);
    }
  };

  const handleConnect = () => {
    const utcString = new Date().toISOString().split(".")[0] + ".00Z";
    setBtnLoading(true);

    if (data?.status === "in-progress-appointment") {
      getAppointmentRecord({ appointmentId: data?.appointmentId })
        .then(async (res) => {
          if (
            res?.data?.sessionToken &&
            (res?.data?.intakeQuestions?.preferredChannel === "video" ||
              res?.data?.intakeQuestions?.preferredChannel === "video/chat")
          ) {
            // Handle video/chat session
            const base64Url = res?.data?.sessionToken?.split(".")[1];
            const payloadinit = atob(
              base64Url.replace(/-/g, "+").replace(/_/g, "/")
            );
            const parseddata = JSON.parse(payloadinit || "{}");

            const topicForSession = parseddata?.tpc;
            const passwordForSession = parseddata?.password;

            await getSignature({
              topic: topicForSession,
              role: 1,
              password: passwordForSession,
            }).then((response) => {
              navigate("/app/zoom-meeting-create", {
                state: {
                  encounterId: data?.id,
                  appointmentData: res?.data,
                  topic: topicForSession,
                  sessionToken: response?.data?.sessionToken,
                  password: passwordForSession,
                  patientName: data?.subject?.display,
                  questionnairInfo: data?.intakeQuestions,
                },
              });
            });
          } else {
            // Handle phone call session
            navigate("/app/phone-call-create", {
              state: {
                practitionerName,
                practitionerId,
                patientId: data?.subject?.reference?.split("/")[1],
                patientName: data?.subject?.display,
                encounterId: data?.id,
                appointmentData: res?.data,
                currentUser: {
                  patient: { display: data?.subject?.display },
                },
                questionnairInfo: data?.intakeQuestions,
                previousStatus: data?.status,
              },
            });
          }
        })
        .catch((res) => {
          failed(
            res?.response?.data?.message ||
            res?.response?.data?.error ||
            res.message
          );
        })
        .finally(() => setBtnLoading(false));
    } else if (data?.status === "planned" || data?.status === "in-progress-appointment") {
      const preferredChannel = data?.intakeQuestions?.preferredChannel?.toLowerCase();
      if (preferredChannel === "phone") {
        handleCall(data);
      } else {
        handleVideoCall(data);
      }
    } else if (data?.status === "in-progress-intake") {
      setBtnLoading(true);
      navigate("/app/phone-call-create", {
        state: {
          practitionerName: practitionerName,
          practitionerId: practitionerId,
          patientId: data?.subject?.reference?.split("/")[1],
          patientName: data?.subject?.display,
          encounterId: data?.id,
          currentUser: {
            patient: { display: data?.subject?.display },
          },
          proceedWith: data?.proceedWith,
          requestedPeriod: data?.period,
          questionnairInfo: data?.intakeQuestions,
          period: data?.period,
          previousStatus: data?.status,
        },
      });
      setBtnLoading(false);
    } else {
      const currentActor = {
        reference: `Practitioner/${practitionerId}`,
        display: practitionerName,
        type: "Practitioner",
      };
      const newParticipant = {
        individual: {
          reference: `Practitioner/${practitionerId}`,
          display: practitionerName,
          type: "Practitioner",
        },
        period: {
          end: null,
          start: utcString,
        },
      };
      const updatedParticipant = [...data?.participant, newParticipant];

      let encounterPayload = {
        patient: data?.subject,
        orgId: data?.orgId,
        questionnairInfo: data?.intakeQuestions,
        requestedPeriod: data?.requestedPeriod,
        practitionerId: data?.practitionerId,
        practitionerName: practitionerName,
        proceedWith: data?.proceedWith,
        status: "in-progress-waitingroom",
        encounterId: data?.id,
        patientLocation: data?.patientLocation,
        participant: updatedParticipant,
        actor: currentActor,
        encounterClass: "NULL",
      };

      editEncounter(encounterPayload)
        .then((res) => {
          if (res?.status === true) {
            success("Encounter In Progress");
          }
        })
        .catch((res) =>
          failed(
            res?.response?.data?.message ||
            res?.response?.data?.error ||
            res.message
          )
        )
        .finally(() => setBtnLoading(false));

      navigate("/app/phone-call-create", {
        state: {
          practitionerName: practitionerName,
          practitionerId: practitionerId,
          patientId: data?.subject?.reference?.split("/")?.[1],
          patientName: data?.subject?.display,
          encounterId: data?.id,
          currentUser: {
            patient: { display: data?.subject?.display },
          },
          proceedWith: "WAITING_ROOM",
          requestedPeriod: data?.period,
          questionnairInfo: data?.intakeQuestions,
          period: data?.period,
          previousStatus: data?.status,
        },
      });
    }
  };

  const handleCancelRequest = () => {
    setConfirmModalShow(true);
  };

  const handleCancelConfirm = () => {
    setConfirmModalShow(false);
    setBtnLoading(true);
    const currentActor = {
      reference: `Practitioner/${practitionerId}`,
      display: practitionerName,
      type: "Practitioner",
    };
    let encounterPayload = {
      patient: data?.subject,
      orgId: data?.orgId,
      questionnairInfo: data?.intakeQuestions,
      requestedPeriod: data?.requestedPeriod,
      practitionerId: data?.practitionerId,
      practitionerName: practitionerName,
      proceedWith: data?.proceedWith,
      status: "cancelled",
      encounterId: data?.id,
      patientLocation: data?.patientLocation,
      participant: data?.participant,
      actor: currentActor,
      encounterClass: "NULL",
    };

    editEncounter(encounterPayload)
      .then((res) => {
        if (res?.status === true) {
          success("Encounter Cancelled Successfully");
          refreshEncounters();
          onHide();
        }
      })
      .catch((res) =>
        failed(
          res?.response?.data?.message ||
          res?.response?.data?.error ||
          res.message
        )
      )
      .finally(() => setBtnLoading(false));
  };

  return (
    <Modal
      backdropClassName
      backdrop={"static"}
      show={onShow}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="custom-dialog appointment-custom-modal"
    >
      <Modal.Header closeButton className="border-0">
        <span className="header-text">Patient:</span>&nbsp;&nbsp;
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ textTransform: "capitalize" }}
        >
          {data?.subject?.display}
        </Modal.Title>
        {data?.intakeQuestions?.preferredChannel === "phone" ||
          data?.intakeQuestions?.preferredChannel === "video/chat" ? (
          <div className="action-wrap">
            {data?.intakeQuestions?.preferredChannel === "phone" ? (
              <img src={Audio} alt="Audio Channel" />
            ) : (
              <img src={Video} alt="Video Channel" />
            )}
          </div>
        ) : null}
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12}>
            <b>Encounter Details</b>
            <div className="col-section">
              <Row>
                <Col>
                  <span className="label-grey">Encounter ID:</span>{" "}
                  {data?.id || "-"}
                </Col>
                <Col>
                  <span className="label-grey">Status:</span>{" "}
                  {data?.status || "-"}
                </Col>
              </Row>
              <Row>
                <Col>
                  <span className="label-grey">Reason for Call:</span>{" "}
                  {data?.intakeQuestions?.reason ||
                    data?.intakeQuestions?.intakeReason ||
                    data?.intakeQuestions?.["Reason for call"] ||
                    "-"}
                </Col>
              </Row>
            </div>
          </Col>

          <Col xs={12} className="mt-4">
            <b>Patient Details</b>
            <div className="col-section">
              <Row>
                <Col>
                  <span className="label-grey">Date of Birth:</span>{" "}
                  {patientDetails?.birthDate || "-"}
                </Col>
                <Col>
                  <span className="label-grey">Gender:</span>{" "}
                  {patientDetails?.gender
                    ? patientDetails?.gender.charAt(0).toUpperCase() +
                    patientDetails?.gender.slice(1)
                    : "-"}
                </Col>
              </Row>
              <Row>
                <Col>
                  <span className="label-grey">Caller Name:</span>{" "}
                  {data?.intakeQuestions?.callerName || "-"}
                </Col>
                <Col>
                  <span className="label-grey">
                    Return Phone Number:
                  </span>{" "}
                  {data?.intakeQuestions?.callerNumber || "-"}
                </Col>
              </Row>
              <Row>
                <Col>
                  <span className="label-grey">Practitioner:</span>{" "}
                  {practitionerName}
                </Col>
                <Col>
                  <span className="label-grey">Patient HCN: </span>{" "}
                  {patientHCN}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <div className="btn-wrap-block mt-4">
          <div
            className="action-wrap"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            {data?.status !== "cancelled" &&
              data?.status !== "finished" &&
              ((data?.status === "in-progress-intake" &&
                data?.subject?.display !== undefined) ||
                data?.status !== "in-progress-intake") &&
              data?.statusHistory &&
              data.statusHistory.length > 0 &&
              data.statusHistory[data.statusHistory.length - 1]?.actor?.reference
                ?.split("/")[1] === practitionerId && (
                <Button
                  variant="primary"
                  onClick={() => handleConnect(data)}
                  className="success-btn"
                  style={{
                    margin: "0 7px 0 0",
                    backgroundColor: "green",
                  }}
                >
                  Launch
                </Button>
              )}

            {data?.status === "in-progress-intake" &&
              data?.subject?.display === undefined && (
                <Button
                  variant="danger"
                  onClick={handleCancelRequest}
                  className="danger-btn"
                  style={{ backgroundColor: "red" }}
                >
                  Cancel
                </Button>
              )}
          </div>
        </div>

        <ConfirmationModal
          show={confirmModalShow}
          onConfirm={handleCancelConfirm}
          onCancel={() => setConfirmModalShow(false)}
          message="Are you sure you want to cancel this encounter?"
        />
      </Modal.Body>
    </Modal>
  );
};
