import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "./EncounterDetail.css";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { ArrowBack, ExpandMore } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetPatientById } from "../../../hooks/ReactQueryHooks/useGetPatientById";
import moment from "moment";
import { usePatientEncounterHistoryQuery } from "../../../hooks/ReactQueryHooks/usePatientEncounterHistoryQuery";
import { useEncounterQuestionnaireResponseQuery } from "../../../hooks/ReactQueryHooks/useQuestionnaireResponseQuery";
import { useSelector } from "react-redux";
import { Button } from "../../common/Button";
import ServiceLocationModal from "../ServiceRequest/ServiceLocationModal";
import html2pdf from "html2pdf.js";
import { axios } from "../../../lib/axios";
import { getOrganization } from "../../api/Organization";
import { failed, success } from "../../common/Toastify";
import {
  getSingleUser,
  getPractitionerForReport,
  getPatientData,
} from "../../api/Individual";
import { imageURLToBase64 } from "../../../utils/ImageToBase64";
import { FullPageSpinner } from "../../common/Spinner/FullPageSpinner";
import {
  ViewDate,
  ViewTime,
  ViewDateTime,
  safeAgeString,
} from "../../../utils/DateSupport";
import { createDocument } from "../api/commonApis.js";
import AddendumModal from "./AddendumModal.js";
import { UpdateEncounterDocumentation } from "./UpdateEncounterDocumentation.js";
import { useGetOrgLicensedProducts } from "../../../hooks/ReactQueryHooks/useGetOrgLicensedProducts.js";
import { getProtocolResponsesByEncounterId } from "../api/Protocol/index.js";
import UpdateProtocolAssessmentModal from "./UpdateProtocolAssessmentModal.js";
import { confidentialStatement } from "./Constants.js";

{
  /* This file replaces the EncounterDetail.js file. It removes whitespace between the components to reduce the size of encounter report */
}

const EncounterReport = ({
  PractionerData,
  EncounterId,
  setEncouterDetailsShow = null,
  ShowCompleteButton = false,
}) => {
  const navigate = useNavigate();
  const [patientData, setPatientData] = useState(null);
  const [encounteredData, setEncounteredData] = useState([]);
  const [appointmentData, setAppointmentData] = useState([]);
  const [encounterQuestionairData, setEncounterQuestionairData] = useState([]);
  const { organizations, organizationLogo } = useSelector(
    (state) => state?.auth?.user
  );
  const { state } = useLocation();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const [patientId, setPatientId] = useState(null);
  const [practitionerId, setPractitionerId] = useState(null);
  const [practionerData, setPractionerData] = useState({});
  const [myUserId, setMyUserId] = useState("");
  const encounterId =
    state?.encounterId || urlParams.get("encounterId") || EncounterId;
  const from = state?.from;
  const notPatientDashboard = state?.notPatientDashboard;
  const [openModal, setOpenModal] = useState(false);
  const [base64Url, setBase64Url] = useState("");
  const user = useSelector((state) => state?.auth?.user);
  const [orgnization, setOrganizations] = useState({});
  const [practitionerDetails, setPractitionerDetails] = useState([]);
  const [base64SignImg, setBase64SignImg] = useState("");
  const [base64OrgImg, setBase64OrgImg] = useState("");
  const [loadingData, setLoadingData] = useState(false);
  const adminOrgs = useSelector((state) => state?.auth?.user?.organizations);
  const [addendumModalShow, setAddAddendumModalShow] = useState(false);
  const [UpdateEncounterModalShow, setUpdateEncounterModalShow] =
    useState(false);
  const { isFetched, data: orgLicensedProducts } = useGetOrgLicensedProducts(
    {}
  );
  const permissions = useSelector((state) => state?.auth?.user?.permissions);
  const isContactCenterActive = orgLicensedProducts?.callCenter;
  const [protocolResponseData, setProtocolResponseData] = useState(null);
  const [finalDisposition, setFinalDisposition] = useState(null);
  const [protocolAssessmentUpdateModal, setProtocolAssessmentModal] =
    useState(false);

  const onSuccessForEncounterQuestionnaireResponseQuery = async (data) => {
    setEncounterQuestionairData(data);
  };

  const { refetch } = useEncounterQuestionnaireResponseQuery({
    onSuccess: onSuccessForEncounterQuestionnaireResponseQuery,
    encounterId,
  });

  useEffect(() => {
    getOrganization(user?.organizations?.[0]?.id)
      .then((res) => {
        setOrganizations(res.data);
      })
      .catch((res) => {
        failed(
          res?.response?.data?.message ||
            res?.response?.data?.error ||
            res.message
        );
      });
  }, []);

  useEffect(() => {
    if (from === "fromServiceReq")
      localStorage.setItem("activeTableKey", "seventh");
    else if (from === "fromMedicationReq")
      localStorage.setItem("activeTableKey", "eighth");
    else if (from === "pastEncounter")
      localStorage.setItem("activeTableKey", "fifth");
  }, []);

  useEffect(() => {
    const apiBody = {
      id: user?.["custom:unique_id"],
      type: "Practitioner",
      userOrgId: adminOrgs?.[0]?.id,
    };
    getSingleUser(apiBody)
      .then((res) => {
        setPractionerData(res?.data);
        setMyUserId(res?.data?.link?.[0]?.target?.reference?.split("/")?.[1]);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (encounterId) {
        setLoadingData(true);
        try {
          const response = await axios.get(`encounter/${encounterId}`);
          const encounterData = response?.data;
          let patientId = encounterData?.subject?.reference
            ? encounterData?.subject?.reference?.split("/")[1]
            : "";
          let practitioners =
            encounterData?.participant?.filter(
              (participant) =>
                participant.individual &&
                participant.individual.type === "Practitioner"
            ) || "";
          let lastPractionerId =
            encounterData.statusHistory
              .find((entry) => entry.status === "follow-up")
              ?.actor.reference.split("/")[1] ||
            practitioners?.[
              practitioners.length - 1
            ]?.individual?.reference?.split("/")[1] ||
            "";
          setEncounteredData(response?.data);
          setBase64OrgImg(organizationLogo);
          if (encounterData?.appointmentId) {
            try {
              const appointment = await axios.get(
                `appointment/${response.data.appointmentId}`
              );
              setAppointmentData(appointment?.data);
            } catch {}
          }
          if (patientId) {
            fetchPatient(patientId);
          }
          if (lastPractionerId) {
            fetchPractitioner(lastPractionerId);
          }
        } catch (error) {
          console.error("Error:", error);
        }
        setLoadingData(false);
      }
    };
    fetchData();
  }, [addendumModalShow]);

  const handleSendDocument = () => {
    const element = document.getElementById("encounter-detail");
    const styleElement = document.createElement("style");
    styleElement.innerHTML = `
    .small-font-encounter-report { font-size: 10px !important; }
    .big-font-encounter-report { font-size: 10px !important; }
  `;
    document.head.appendChild(styleElement);
    html2pdf()
      .from(element)
      .set({
        margin: [0.25, 0.25, 0.25, 0.25],
        filename: `Encounter/${encounterId}`,
        image: { type: "jpeg", quality: 2 },
        html2canvas: { scale: 3, useCORS: true, letterRendering: true },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
        pagebreak: {
          mode: ["avoid-all", "css"],
          before: [".service-info-block", ".questions-wrapper"],
          avoid: [".header", ".common-listing"],
        },
      })
      .toPdf()
      .output("datauristring")
      .then((pdfDataUri) => {
        const base64String = pdfDataUri.split(",")[1];
        setBase64Url(base64String);
        createDocument({
          practitionerId: user?.["custom:unique_id"],
          requestType: "Encounter Report",
          patientId: patientId,
          orgId: organizations?.[0]?.id,
          document: base64Url,
          category: "Encounter Report",
          orgName: organizations?.[0]?.name,
          documentName: `Encounter/${encounterId}`,
          encounterId: encounterId,
        })
          .catch((res) => {
            failed(
              res?.response?.data?.message ||
                res?.response?.data?.error ||
                res.message
            );
          })
          .finally(() => setOpenModal(encounteredData));
      });
  };

  const fetchPractitioner = async (practitionerId) => {
    await getPractitionerForReport({ practitionerId: practitionerId })
      .then((practitioner) => {
        setPractitionerDetails(practitioner?.result);
        setPractitionerId(practitionerId);
        setBase64SignImg(practitioner?.result?.signatureURL?.base64Data);
      })
      .catch((error) => {
        failed(
          error?.response?.data?.message ||
            error?.response?.data?.error ||
            error.message
        );
      });
  };

  const fetchPatient = async (patientId) => {
    await getPatientData(patientId)
      .then((res) => {
        setPatientData(res?.result);
        setPatientId(patientId);
      })
      .catch((error) => {
        failed(
          error?.response?.data?.message ||
            error?.response?.data?.error ||
            error.message
        );
      });
  };

  async function signImageURLToBase64(url) {
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: { "Cache-Control": "no-cache" },
      });
      const blob = await response.blob();
      const base64String = await blobToBase64(blob);
      return base64String;
    } catch (error) {
      console.error("Error converting image URL to base64:", error);
      throw error;
    }
  }

  function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
    });
  }
  const capitalizeWords = (str) => {
    if (str) {
      return str
        .toLowerCase()
        .replace(/(^|\s)\S/g, (firstLetter) => firstLetter.toUpperCase());
    } else {
      return "";
    }
  };
  function hasRelevantAnswer(encounter) {
    // Check if encounter and encounter.item exist and are valid arrays
    if (!encounter || !Array.isArray(encounter.item)) {
      return false;
    }

    // Iterate through each item in the encounter
    for (const data of encounter.item) {
      // Check if data.answer exists and is an array
      if (Array.isArray(data.answer)) {
        // Check if the first answer has a non-empty valueString
        if (data.answer[0]?.valueString) {
          return true;
        }

        // Check if any answer has valueBoolean set to true
        if (data.answer.some((ele) => ele?.valueBoolean === true)) {
          return true;
        }
      }
    }

    // If none of the items meet the conditions, return false
    return false;
  }

  const handleCompleteEncounter = async () => {
    const actor = {
      display: practionerData.name[0].text,
      reference: `Practitioner/${myUserId}`,
      type: "Practitioner",
    };
    return axios
      .put(`encounter/${encounterId}`, {
        status: "finished",
        actor: actor,
        period: {
          start: encounteredData?.period.start,
          end: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
        },
        class: "NULL",
      })
      .then((res) => {
        if (!patientId) {
          failed("Something went wrong");
          navigate("/app/dashboard");
        }
        navigate(0);
      })
      .catch((err) =>
        console.log(
          err?.response?.data?.message ||
            err?.response?.data?.error ||
            err?.message
        )
      )
      .finally(() => {});
  };

  const handleUpdateEncounterModalShow = () => {
    setUpdateEncounterModalShow((prev) => !prev);
    if (UpdateEncounterModalShow) {
      setLoadingData(true);
      refetch().finally(() => setLoadingData(false));
    }
  };

  const handleUpdateProtocolClick = () => {
    setProtocolAssessmentModal((prev) => !prev);
  };

  useEffect(() => {
    if (
      isContactCenterActive &&
      permissions.includes("Read Protocol Response")
    ) {
      getProtocolResponsesByEncounterId(encounterId, adminOrgs?.[0]?.id)
        .then((res) => {
          const parsedResponse = JSON.parse(res?.body);
          if (parsedResponse?.data && parsedResponse?.data?.length > 0) {
            setProtocolResponseData(parsedResponse?.data);
            // Filter out the See More Appropriate Guideline
            const filteredData = parsedResponse.data.filter(
              (item) => item.dispositionLevel !== 99
            );

            const finalDisposition = filteredData.reduce((highest, item) => {
              return item.dispositionLevel > (highest?.dispositionLevel || 0)
                ? item
                : highest;
            }, null);
            setFinalDisposition(
              finalDisposition?.overrideDisposition ||
                finalDisposition?.disposition
            );
          }
        })
        .catch((res) => {
          failed(res?.data?.message || res?.message);
        });
    }
  }, [encounterQuestionairData, protocolAssessmentUpdateModal]);

  const extractPlainText = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, "text/html");
    return doc.body.textContent || "";
  };

  const encounterDetails = [
    ["Encounter ID", encounterId || "-"], // 0
    ["Patient Name", capitalizeWords(patientData?.name?.[0]?.text) || "-"], //1
    [
      "Appointment Type",
      appointmentData?.serviceCategory?.[0]?.coding?.[0]?.display || "-",
    ], //2
    
    ["Encounter Date", ViewDate(encounteredData?.period?.end) || "-"],//3

    [
      "Health Card Number",
      patientData?.identifier?.find((ele) => ele?.type === "HCN")?.value || "-",
    ], //4

    [
      "Preferred Channel",
      capitalizeWords(
        appointmentData?.intakeQuestions?.preferredChannel ||
          encounteredData?.intakeQuestions?.preferredChannel
      ) || "-",
    ], //5

    ["Completion Time", ViewTime(encounteredData?.period?.end) || "-"], //6

    ["Patient DOB", (() => {
      const dob = patientData?.birthDate;
      const formattedDate = ViewDate(dob);
      if (!formattedDate) return "-";
      
      // Age calculation function
      const calculateAge = (birthDate) => {
        const today = new Date();
        const birth = new Date(birthDate);
        let age = today.getFullYear() - birth.getFullYear();
        const monthDiff = today.getMonth() - birth.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
          age--;
        }
        return age;
      };
    
      return `${formattedDate} (${calculateAge(dob)} years)`;
    })()], //7

    ["Address", patientData?.address?.[0].line?.[0] || "-"],
    ...(isContactCenterActive && finalDisposition
      ? [["Disposition", finalDisposition || "-"]]
      : []),//8

    [
      "Provider Name",
      capitalizeWords(practitionerDetails?.name?.[0]?.text) || "-",
    ], //9
    
    ["Gender", capitalizeWords(patientData?.gender) || "-"], // 10

    [
      "Return Telephone Number",
      (() => {
        const phoneEntry = encounterQuestionairData
          ?.flatMap(encounter => encounter.item)
          ?.find(item => item.text?.trim() === "Return Telephone Number");
          
        return phoneEntry?.answer?.[0]?.valueString || "-";
      })()
    ], 
    
    
    [
      "Provider Designation",
      capitalizeWords(
        practitionerDetails?.designation === "nursePractitioner"
          ? "Nurse Practitioner"
          : practitionerDetails?.designation
      ) || "-",
    ], //12
    
    [
      "Phone Number",
      patientData?.telecom?.find((ele) => ele?.system === "phone")?.value ||
        "-",
    ], //13

    [],

    [
      "Caller Name", 
      (() => {
        // Get from questionnaire response instead of appointment
        const callerEntry = encounterQuestionairData
          ?.flatMap(encounter => encounter.item)
          ?.find(item => item.text?.trim() === "Caller Name");
        
        const callerName = callerEntry?.answer?.[0]?.valueString;
        const patientName = patientData?.name?.[0]?.text || "";
        return callerName && callerName !== patientName 
          ? capitalizeWords(callerName) 
          : "-";
      })(),
    ], //15
    
    [
      "Relationship to Patient",
      (() => {
        const relationshipEntry = encounterQuestionairData
          ?.flatMap(encounter => encounter.item)
          ?.find(item => item.text?.trim() === "Relationship to Patient");
        
        const relationship = relationshipEntry?.answer
          ?.find(ans => ans.valueString)?.option;
        
        return relationship || "-";
      })()
    ], //16
  
  ];

  if (loadingData) return <FullPageSpinner loadingText="Loading" />;

  return (
    <>
      {openModal ? (
        <ServiceLocationModal
          onHide={() => setOpenModal(false)}
          modalShow={openModal}
          paramsObj={{
            patientId,
            orgId: organizations?.[0]?.id,
            base64: base64Url,
          }}
          practitionerData={user}
          requestType={"Encounter Report"}
          categoryVar={"Encounter Report"}
          patientData={patientData}
          orgName={organizations?.[0]?.name}
          fromServiceRequest={false}
          fromMedicationRequest={false}
          fromEncounter={true}
          encounterId={encounterId}
          documentName={`Encounter/${encounterId}`}
        />
      ) : null}

      {UpdateEncounterModalShow ? (
        <UpdateEncounterDocumentation
          modalShow={UpdateEncounterModalShow}
          handleShow={handleUpdateEncounterModalShow}
          encounterId={encounterId}
          patientId={patientId}
        />
      ) : null}

      {protocolAssessmentUpdateModal && (
        <UpdateProtocolAssessmentModal
          encounterId={encounterId}
          onShow={protocolAssessmentUpdateModal}
          onHide={() => setProtocolAssessmentModal(false)}
        />
      )}

      {addendumModalShow && (
        <AddendumModal
          data={encounteredData}
          onShow={addendumModalShow}
          onHide={() => setAddAddendumModalShow(false)}
        />
      )}
      <section className="common-listing" style={{ paddingTop: "0px" }}>
        {/* Fixed Header that contains buttons */}
        <div
          className="heading-wrap mb-3 d-flex align-items-center justify-content-between header-wrap"
          style={{ position: "sticky", padding: "10px", zIndex: 2 }}
        >
          <div className="page-title-block d-flex align-items-center"></div>

          <div className="d-flex align-items-center">
            {(encounteredData?.status === "in-progress-appointment" ||
              encounteredData?.status === "in-progress-waitingroom" ||
              encounteredData?.status === "in-progress-intake") && (
              <>
                <Button
                  className="me-3"
                  onClick={handleUpdateEncounterModalShow}
                >
                  Update Documentation
                </Button>
              </>
            )}
            {isContactCenterActive &&
              permissions.includes("Write Protocol Response") &&
              protocolResponseData?.length > 0 &&
              (encounteredData?.status === "in-progress-appointment" ||
                encounteredData?.status === "in-progress-waitingroom" ||
                encounteredData?.status === "in-progress-intake") && (
                <>
                  <Button className="me-3" onClick={handleUpdateProtocolClick}>
                    Update Triage Documentation
                  </Button>
                </>
              )}
            {(encounteredData?.status === "in-progress-appointment" ||
              encounteredData?.status === "in-progress-waitingroom" ||
              encounteredData?.status === "in-progress-intake") && (
              <>
                <Button
                  className="me-3"
                  style={{
                    background:
                      "linear-gradient(90deg, #4CAF50 0%, #388E3C 100%)",
                  }}
                  onClick={handleCompleteEncounter}
                >
                  Complete
                </Button>
              </>
            )}

            {encounteredData?.status === "finished" && (
              <>
                <Button
                  onClick={() => navigate("/app/dashboard")}
                  style={{ marginRight: "10px" }}
                >
                  My Dashboard
                </Button>
                <Button
                  onClick={() => setAddAddendumModalShow(true)}
                  style={{ marginRight: "10px" }}
                >
                  Add Addendum
                </Button>
                <Button
                  onClick={() =>
                    orgnization?.sft?.enabled
                      ? orgnization?.sft?.locationDelivery
                        ? handleSendDocument()
                        : failed(
                            "Please enable delivery to location to send document"
                          )
                      : failed("Please enable SFT to send document.")
                  }
                >
                  Send Document
                </Button>
              </>
            )}
          </div>
        </div>
        <section id="encounter-detail">
          {/* Organization Letter Head */}
          <div style={{ textAlign: "center" }}>
            {/* Organization Name and logo on right*/}
            <div style={{ display: "flex", textAlign: "center",flexDirection:"row" ,justifyContent:"center"}}>
              <span
                className="user-name-title fw-bold big-font-encounter-report"
                style={{ display: "inline-block", alignSelf:"center" , width: "100%" }}
              >
                {organizations?.[0]?.name} - Encounter Report
              </span>
              <div className="circular-container" style={{ alignSelf: "flex-end" }}>
                  <img src={base64OrgImg} className="circular-image" />
              </div>

            </div>
            <hr
              style={{
                margin: "2px 0",
                borderWidth: "2px",
                borderColor: "black",
              }}
            />

            {/* Confidential Statement */}
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <p
                className="label-grey small-font-encounter-report"
                style={{
                  margin: "0",
                  padding: "0",
                  flex: 1,
                  textAlign: "left",
                }}
              >
                {confidentialStatement}
              </p>
              
            </div>

            <hr
              style={{
                margin: "2px 0",
                borderWidth: "2px",
                borderColor: "black",
              }}
            />
            {/* Patient and Provider Info Grid */}

            <div
              className="small-font-encounter-report"
              style={{ display: "flex", flexDirection: "row" }}
            > {/* Row 1: EncounterID, Patient Name, Appointment */}
              <div className="element" style={{ display: "flex", width: "100%", flexDirection:"row" }}>{/* Encounter ID */}
                <div className="fw-bold" style={{ textAlign: "left" }}> {encounterDetails[0][0]}: </div> 
                &nbsp;&nbsp;
                <div style={{ display: "flex", justifyContent: "flex-start", textAlign: "left", }}> {encounterDetails[0][1]} </div>
              </div>

              <div className="element" style={{ display: "flex", width: "100%", flexDirection:"row" }}>{/* Patient Name */}
                <div className="fw-bold" style={{ textAlign: "left" }}> {encounterDetails[1][0]}: </div>
                &nbsp;&nbsp;
                <div style={{ display: "flex", justifyContent: "flex-start", textAlign: "left", }}> {encounterDetails[1][1]} </div>
              </div>

              <div className="element" style={{ display: "flex", width: "100%", flexDirection:"row" }}>{/* Appointment Type */}
                <div className="fw-bold" style={{ textAlign: "left" }}> {encounterDetails[2][0]}: </div>
                &nbsp;&nbsp;
                <div style={{ display: "flex", justifyContent: "flex-start", textAlign: "left", }}> {encounterDetails[2][1]} </div>
              </div>
            </div>

            <div
              className="small-font-encounter-report"
              style={{ display: "flex", flexDirection: "row" }}
            > {/* Row 2: Encounter Date, Health Card Number, Preferred Channel */}
              <div className="element" style={{ display: "flex", width: "100%", flexDirection:"row" }}>
                <div className="fw-bold" style={{ textAlign: "left" }}> {encounterDetails[3][0]}: </div>
                &nbsp;&nbsp;
                <div style={{ display: "flex", justifyContent: "flex-start", textAlign: "left", }}> {encounterDetails[3][1]} </div>
              </div>

              <div className="element" style={{ display: "flex", width: "100%", flexDirection:"row" }}>
                <div className="fw-bold" style={{ textAlign: "left" }}> {encounterDetails[4][0]}: </div>
                &nbsp;&nbsp;
                <div style={{ display: "flex", justifyContent: "flex-start", textAlign: "left", }}> {encounterDetails[4][1]} </div>
              </div>

              <div className="element" style={{ display: "flex", width: "100%", flexDirection:"row" }}>
                <div className="fw-bold" style={{ textAlign: "left" }}> {encounterDetails[5][0]}: </div>
                &nbsp;&nbsp;
                <div style={{ display: "flex", justifyContent: "flex-start", textAlign: "left", }}> {encounterDetails[5][1]} </div>
              </div>
            </div>

            <div
              className="small-font-encounter-report"
              style={{ display: "flex", flexDirection: "row" }}
            > {/* Row 3: Completion Time, Patient DOB, Address */}
              <div className="element" style={{ display: "flex", width: "100%", flexDirection:"row" }}>
                <div className="fw-bold" style={{ textAlign: "left" }}> {encounterDetails[6][0]}: </div>
                &nbsp;&nbsp;
                <div style={{ display: "flex", justifyContent: "flex-start", textAlign: "left", }}> {encounterDetails[6][1]} </div>
              </div>

              <div className="element" style={{ display: "flex", width: "100%", flexDirection:"row" }}>
                <div className="fw-bold" style={{ textAlign: "left" }}> {encounterDetails[7][0]}: </div>
                &nbsp;&nbsp;
                <div style={{ display: "flex", justifyContent: "flex-start", textAlign: "left", }}> {encounterDetails[7][1]} </div>
              </div>

              <div className="element" style={{ display: "flex", width: "100%", flexDirection:"row" }}>
                <div className="fw-bold" style={{ textAlign: "left" }}> {encounterDetails[8][0]}: </div>
                &nbsp;&nbsp;
                <div style={{ display: "flex", justifyContent: "flex-start", textAlign: "left", }}> {encounterDetails[8][1]} </div>
              </div>
            </div>

            <div
              className="small-font-encounter-report"
              style={{ display: "flex", flexDirection: "row" }}
            > {/* Row 4: Provider Name, Gender, Return Telephone Number */}
              <div className="element" style={{ display: "flex", width: "100%", flexDirection:"row" }}>
                <div className="fw-bold" style={{ textAlign: "left" }}> {encounterDetails[9][0]}: </div>
                &nbsp;&nbsp;
                <div style={{ display: "flex", justifyContent: "flex-start", textAlign: "left", }}> {encounterDetails[9][1]} </div>
              </div>

              <div className="element" style={{ display: "flex", width: "100%", flexDirection:"row" }}>
                <div className="fw-bold" style={{ textAlign: "left" }}> {encounterDetails[10][0]}: </div>
                &nbsp;&nbsp;
                <div style={{ display: "flex", justifyContent: "flex-start", textAlign: "left", }}> {encounterDetails[10][1]} </div>
              </div>

              <div className="element" style={{ display: "flex", width: "100%", flexDirection:"row" }}>
                <div className="fw-bold" style={{ textAlign: "left" }}> {encounterDetails[11][0]} </div>
                &nbsp;&nbsp;
                <div style={{ display: "flex", justifyContent: "flex-start", textAlign: "left", }}> {encounterDetails[11][1]} </div>
              </div>
            </div>

           {/* Row 5: Provider Designation, Phone Number, Caller Name & relationship to patient (if different from patient Name)*/}
          <div className="small-font-encounter-report" style={{ display: "flex", flexDirection: "row" }}>
            <div className="element" style={{ display: "flex", width: "100%", flexDirection:"row" }}>
              <div className="fw-bold" style={{ textAlign: "left" }}> {encounterDetails[12][0]}: </div>
              &nbsp;&nbsp;
              <div style={{ display: "flex", justifyContent: "flex-start", textAlign: "left", }}> {encounterDetails[12][1]} </div>
            </div>

            <div className="element" style={{ display: "flex", width: "100%", flexDirection:"row" }}>
              <div className="fw-bold" style={{ textAlign: "left" }}> {encounterDetails[13][0]} </div>
              &nbsp;&nbsp;
              <div style={{ display: "flex", justifyContent: "flex-start", textAlign: "left", }}> {encounterDetails[13][1]} </div>
            </div>

            <div className="element" style={{ display: "flex", width: "100%", flexDirection:"column" }}>
              {/* Conditionally rendering Caller Name and Relationship to Patient */}
              {encounterDetails[15][1] !== encounterDetails[1][1] && encounterDetails[15][1] !== "-" && (
                <>
                  <div className="element" style={{ display: "flex", width: "100%", flexDirection:"row" }}>
                    <div className="fw-bold" style={{ textAlign: "left" }}> {encounterDetails[15][0]} </div>
                    &nbsp;&nbsp;
                    <div style={{ display: "flex", justifyContent: "flex-start", textAlign: "left", }}> {encounterDetails[15][1]} </div>
                  </div>

                  <div className="element" style={{ display: "flex", width: "100%", flexDirection:"row" }}>
                    <div className="fw-bold" style={{ textAlign: "left" }}>{encounterDetails[16][0]}:</div>
                    &nbsp;&nbsp;
                    <div style={{ textAlign: "left" }}>{encounterDetails[16][1]}</div>
                  </div>
                </>
              )}
            </div>
          </div>

            <hr
              style={{
                margin: "2px 0",
                borderWidth: "2px",
                borderColor: "black",
              }}
            />

            {/* Questionnaire Info */}
            {encounterQuestionairData?.length > 0 &&
              encounterQuestionairData?.map(
                (encounter, index) =>
                  hasRelevantAnswer(encounter) && (
                    <div key={`encounter-${index}`}>
                      <div
                        className="small-font-encounter-report"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          {encounter?.questionnaireTitle}
                        </span>
                        <span>
                          {capitalizeWords(encounter?.author?.display)}
                          {" - "}
                          {ViewDateTime(encounter?.updatedAt)}
                        </span>
                      </div>
                      <hr
                        style={{
                          margin: "2px 0",
                          borderWidth: "2px",
                          borderColor: "black",
                        }}
                      />

                      {/* Questionnaire */}
                      {encounter?.item?.map((data) => (
                        <div style={{ pageBreakInside: "avoid" }}>
                          <div
                            className="small-font-encounter-report"
                            style={{
                              display: "flex",
                              width: "100%",
                              alignItems: "center",
                            }}
                          >
                            {(data?.answer?.[0]?.valueString ||
                              (data?.questionType === "radio" &&
                                data?.answer?.some(
                                  (ele) => ele?.valueBoolean === true
                                )) ||
                              (data?.questionType === "checkbox" &&
                                data?.answer?.some(
                                  (ele) => ele?.valueBoolean === true
                                ))) && (
                              <div style={{ width: "30%", textAlign: "left" }}>
                                {data?.text}
                              </div>
                            )}
                            <div style={{ width: "70%", textAlign: "left" }}>
                              {data?.answer?.[0]?.valueString && (
                                <div>
                                  {extractPlainText(
                                    data?.answer?.[0]?.valueString
                                  )}
                                </div>
                              )}
                              {data?.answer?.some(
                                (ele) => ele?.valueBoolean === true
                              ) &&
                                data?.answer?.map(
                                  (ele) =>
                                    ele?.valueBoolean === true && (
                                      <div key={ele.id}>{ele?.option}</div>
                                    )
                                )}
                            </div>
                          </div>
                        </div>
                      ))}
                      <hr
                        style={{
                          margin: "2px 0",
                          borderWidth: "2px",
                          borderColor: "black",
                        }}
                      />
                    </div>
                  )
              )}

            {/* Protocol Assessment Details */}

            {isContactCenterActive && protocolResponseData?.length > 0 && (
              <div>
                <div
                  className="fw-bold mt-3 small-font-encounter-report"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <span>
                    Protocol Assessment - (Final Disposition: {finalDisposition}
                    )
                  </span>
                </div>
              </div>
            )}

            {protocolResponseData?.length > 0 &&
              protocolResponseData?.map((item, index) => {
                const hasContent =
                  item?.initialAssessment?.length > 0 ||
                  item?.positiveIndicators?.length > 0 ||
                  item?.careAdvice?.length > 0;

                return (
                  hasContent && (
                    <div key={`encounter-${index}`}>
                      <hr
                        style={{
                          margin: "2px 0",
                          borderWidth: "2px",
                          borderColor: "black",
                        }}
                      />
                      <div
                        className="small-font-encounter-report"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          pageBreakInside: "avoid",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Protocol: {item?.algorithm?.display}
                          {" - "}
                          {item?.algorithm?.category}
                        </span>
                        <span>
                          {capitalizeWords(item?.author?.display)}
                          {" - "} {ViewDateTime(item?.updatedAt)}
                        </span>
                      </div>

                      <hr
                        style={{
                          margin: "2px 0",
                          borderWidth: "2px",
                          borderColor: "black",
                        }}
                      />

                      {/* Disposition */}
                      <div
                        className="small-font-encounter-report"
                        style={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          pageBreakInside: "avoid",
                        }}
                      >
                        <div
                          style={{
                            width: "15%",
                            textAlign: "left",
                            fontWeight: "bold",
                          }}
                        >
                          Disposition:
                        </div>
                        <div style={{ width: "85%", textAlign: "left" }}>
                          {item?.overrideDisposition && (
                            <div>
                              <span>
                                Override Disposition:{" "}
                                {item?.overrideDisposition}
                              </span>
                            </div>
                          )}
                          {item?.disposition && item?.overrideDisposition && (
                            <div>
                              <span>
                                Original Disposition: {item?.disposition}
                              </span>
                            </div>
                          )}
                          {item?.disposition && !item?.overrideDisposition && (
                            <div>
                              <span>{item?.disposition}</span>
                            </div>
                          )}
                          {item?.dispositionRationale && (
                            <div>
                              <span>
                                Override Rationale: {item?.dispositionRationale}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <hr
                        style={{
                          margin: "2px 0",
                          borderWidth: "2px",
                          borderColor: "black",
                        }}
                      />
                      {/* Initial Assessment */}
                      <div
                        className="small-font-encounter-report"
                        style={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          pageBreakInside: "avoid",
                        }}
                      >
                        <div
                          style={{
                            width: "15%",
                            textAlign: "left",
                            fontWeight: "bold",
                          }}
                        >
                          Initial Assessment:
                        </div>
                        <div style={{ width: "85%", textAlign: "left" }}>
                          {item?.initialAssessment?.map((question, i) => (
                            <div key={`question-${i}`}>
                              <div>Q: {question?.question}</div>
                              <div>A: {question?.answer}</div>
                              <hr
                                style={{
                                  margin: "2px 0",
                                  borderWidth: "2px",
                                  borderColor: "black",
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </div>

                      <hr
                        style={{
                          margin: "2px 0",
                          borderWidth: "2px",
                          borderColor: "black",
                        }}
                      />

                      {/* Positive Indicator */}
                      <div
                        className="small-font-encounter-report"
                        style={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          pageBreakInside: "avoid",
                        }}
                      >
                        <div
                          style={{
                            width: "15%",
                            textAlign: "left",
                            fontWeight: "bold",
                          }}
                        >
                          Positive Indicator:
                        </div>
                        <div style={{ width: "85%", textAlign: "left" }}>
                          {item?.positiveIndicators?.map(
                            (question, i) =>
                              question?.answer === "Yes" && (
                                <div key={`question-${i}`}>
                                  <div>
                                    <ul
                                      style={{
                                        paddingLeft: "0",
                                        marginLeft: "0",
                                        marginBottom: "0",
                                      }}
                                    >
                                      <li>{question?.questionText}</li>
                                    </ul>
                                  </div>
                                </div>
                              )
                          )}
                        </div>
                      </div>

                      <hr
                        style={{
                          margin: "2px 0",
                          borderWidth: "2px",
                          borderColor: "black",
                        }}
                      />

                      {/* Negative Indicator */}
                      <div
                        className="small-font-encounter-report"
                        style={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          pageBreakInside: "avoid",
                        }}
                      >
                        <div
                          style={{
                            width: "15%",
                            textAlign: "left",
                            fontWeight: "bold",
                          }}
                        >
                          Negative Indicators:
                        </div>
                        <div style={{ width: "85%", textAlign: "left" }}>
                          {item?.positiveIndicators?.map(
                            (question, i) =>
                              question?.answer === "No" && (
                                <div key={`question-${i}`}>
                                  <div>
                                    <ul
                                      style={{
                                        paddingLeft: "0",
                                        marginLeft: "0",
                                        marginBottom: "0",
                                      }}
                                    >
                                      <li>{question?.questionText}</li>
                                    </ul>
                                  </div>
                                </div>
                              )
                          )}
                        </div>
                      </div>

                      <hr
                        style={{
                          margin: "2px 0",
                          borderWidth: "2px",
                          borderColor: "black",
                        }}
                      />

                      {/* Care Advice */}
                      <div
                        className="small-font-encounter-report"
                        style={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          pageBreakInside: "avoid",
                        }}
                      >
                        <div
                          style={{
                            width: "15%",
                            textAlign: "left",
                            fontWeight: "bold",
                          }}
                        >
                          Care Advice:
                        </div>
                        <div style={{ width: "85%", textAlign: "left" }}>
                          {item?.careAdvice?.map((advice, i) => (
                            <div key={`advice-${i}`}>
                              <div>
                                <ul
                                  style={{
                                    paddingLeft: "0",
                                    marginLeft: "0",
                                    marginBottom: "0",
                                  }}
                                >
                                  <li>{advice?.adviceHeading}</li>
                                  <ul>
                                    {advice?.subAdvices?.map((subItem, j) => (
                                      <li key={`subAdvice-${j}`}>
                                        {subItem?.text}
                                      </li>
                                    ))}
                                  </ul>
                                </ul>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <hr />
                      {isContactCenterActive &&
                        protocolResponseData?.length > 0 && (
                          <div>
                            <div
                              className="fw-bold mt-3 small-font-encounter-report"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <span>-------- END OF PROTOCOL --------</span>
                            </div>
                          </div>
                        )}
                    </div>
                  )
                );
              })}

            {/* Document Delivery Info */}
            {encounteredData?.documentDelivery &&
              encounteredData?.documentDelivery.some(
                (delivery) => delivery?.type !== "HOSP"
              ) && (
                <>
                  {/* Service Request sent */}
                  {encounteredData?.documentDelivery.some(
                    (delivery) => delivery?.type !== "PHARM"
                  ) ? (
                    <div style={{ pageBreakInside: "avoid" }}>
                      <div
                        className="small-font-encounter-report"
                        style={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "15%",
                            textAlign: "left",
                            fontWeight: "bold",
                          }}
                        >
                          Service Request Sent:
                        </div>
                        {encounteredData?.documentDelivery
                          ?.filter(
                            (delivery) =>
                              delivery?.type !== "PHARM" &&
                              delivery?.type !== "HOSP"
                          )
                          ?.map((ele, index) => (
                            <div
                              style={{ width: "85%", textAlign: "left" }}
                              key={index}
                            >
                              <div>
                                <span>
                                  Name: {capitalizeWords(ele?.display)}
                                </span>
                              </div>
                              <div>
                                <span>
                                  Fax:{" "}
                                  {ele?.telecom?.find(
                                    (data) => data?.system === "fax"
                                  )?.value || "-"}
                                </span>
                              </div>
                              <div>
                                Type: {ele?.type === "MBL" && "Lab"}
                                {ele?.type === "RADDX" && "Diagnostics"}
                                {ele?.type === "DX" && "Referral"}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ) : null}

                  {/* Medication Request sent */}
                  {encounteredData?.documentDelivery.some(
                    (delivery) => delivery?.type === "PHARM"
                  ) ? (
                    <div style={{ pageBreakInside: "avoid" }}>
                      <div
                        className="small-font-encounter-report"
                        style={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "15%",
                            textAlign: "left",
                            fontWeight: "bold",
                          }}
                        >
                          Prescription Sent:
                        </div>
                        {encounteredData?.documentDelivery
                          ?.filter((delivery) => delivery?.type === "PHARM")
                          ?.map((ele, index) => (
                            <div
                              style={{ width: "85%", textAlign: "left" }}
                              key={index}
                            >
                              <div>
                                <span>
                                  Name: {capitalizeWords(ele?.display)}
                                </span>
                              </div>

                              <div>
                                <span>
                                  Fax:{" "}
                                  {ele?.telecom?.find(
                                    (data) => data?.system === "fax"
                                  )?.value || "-"}
                                </span>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ) : null}
                </>
              )}
          </div>
          {/* Practitioner Details */}
          <div>
            <h6 className="mt-3">
              {practitionerDetails.length ? <b>Practitioner</b> : null}
            </h6>

            {/* Practitioner Signature */}
            <div className="body-info pt-0 pb-2">
              <Row>
                <Col md={4}>
                  {base64SignImg && (
                    <img
                      width={80}
                      src={base64SignImg}
                      style={{ marginLeft: "10px" }}
                    />
                  )}
                  {practitionerDetails && practitionerDetails?.name && (
                    <h6 className="info-title mt-2 mb-2 small-font-encounter-report">
                      {" "}
                      {practitionerDetails?.name?.[0]?.text
                        ?.split(" ")
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")}
                    </h6>
                  )}
                  {practitionerDetails && practitionerDetails?.designation && (
                    <h5
                      className="info-details m-0 small-font-encounter-report"
                      style={{ color: "#878686" }}
                    >
                      {practitionerDetails?.designation === "nursePractitioner"
                        ? "Nurse Practitioner"
                        : practitionerDetails?.designation
                            ?.split(" ")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1)
                            )
                            .join(" ")}
                    </h5>
                  )}
                </Col>
              </Row>
              <hr
                style={{
                  margin: "2px 0",
                  borderWidth: "2px",
                  borderColor: "black",
                }}
              />
            </div>
          </div>

          {/* Addendum Details */}
          {encounteredData?.note && encounteredData?.note.length > 0 && (
            <div
              className="small-font-encounter-report"
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "15%",
                  textAlign: "left",
                  fontWeight: "bold",
                }}
              >
                Addenda:
              </div>
              {encounteredData?.note?.map((data) => (
                <div style={{ width: "85%", textAlign: "left" }}>
                  {data?.text} - Added By:{" "}
                  {capitalizeWords(data?.authorReference?.display)} -{" "}
                  {ViewDateTime(data?.time)}
                </div>
              ))}
            </div>
          )}
        </section>
      </section>
    </>
  );
};

export default EncounterReport;
