import { useEffect, useState } from "react";
import { Button } from "../../common/Button";
import moment from "moment";
import "./ProtocolResponse.css";

import PositiveIndicators from "./PositiveIndicators";
import { capitalizeWords } from "../../../utils/capitalizeWords";

const ProtocolResponseFill = ({
  protocolState,
  setProtocolState,
  stateId,
  clearProtocolState,
  patientData,
  encounterData,
  handleProtocolRedirect
}) => {
  const [patientAge, setPatientAge] = useState(0);
  const [selectedProtocolType, setSelectedProtocolType] = useState("Adult");

  // State to store the protocol data
  const [protocolData, setProtocolData] = useState([]);

  // Calculate patient age for pre-selection of protocol type
  useEffect(() => {
    const calculateAge = () => {
      const dob = moment(patientData?.birthDate, "DD-MMM-YYYY");
      const today = moment();
      const age = today.diff(dob, "years");
      setPatientAge(age);
      if (age > 17) {
        setSelectedProtocolType("Adult");
      } else {
        setSelectedProtocolType("Pediatric");
      }
    };
    if (protocolState?.protocolData) {
      setSelectedProtocolType(protocolState?.protocolData?.category);
    } else {
      calculateAge();
    }
  }, [patientData?.birthDate, protocolState?.protocolData]);

  // Patient Dashboard button click handler
  const handlePatientDashboardClick = () => {
    const baseUrl = window.location.origin;
    const url = `${baseUrl}/app/patients-details?patientId=${patientData?.id}`;
    window.open(url, "_blank");
  };

  return (
    <>
      {/*<div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="left-wrap">
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: "20px",
                alignItems: "center",
                marginTop: "5px",
              }}
            >
              <div>
                <span className="label">Patient Name:</span>
                <span className="value">
                  {capitalizeWords(patientData?.name[0]?.text)}
                </span>
              </div>
              <div>
                <span className="label">Date of Birth:</span>
                <span className="value">
                  {moment(patientData?.birthDate, "DD-MMM-YYYY").format(
                    "DD-MMM-YYYY"
                  )}{" "}
                  (Age: {patientAge})
                </span>
              </div>
              <div>
                <span className="label">Gender:</span>
                <span className="value">
                  {capitalizeWords(patientData?.gender)}
                </span>
              </div>
            </div>

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: "20px",
                alignItems: "center",
              }}
            >
              <div>
                <span className="label">Caller Name:</span>
                <span className="value">
                  {encounterData?.intakeQuestions?.callerName}
                </span>
              </div>
              <div>
                <span className="label">Relationship:</span>
                <span className="value">
                  {encounterData?.intakeQuestions?.callerRelationship}
                </span>
              </div>
              <div>
                <span className="label">Return Telephone Number:</span>
                <span className="value">
                  {patientData?.telecom?.find(
                    (item) => item?.system === "phone"
                  )?.value || "-"}
                </span>
              </div>
            </div>
          </div>

          <div className="right-wrap">
            <Button
              className="custom-btn"
              title="Open Dashboard in a new tab"
              variant="primary"
              onClick={handlePatientDashboardClick}
              style={{ marginRight: "10px" }}
            >
              Patient Dashboard
            </Button>
          </div>
        </div>
        <hr style={{ margin: "10px 0" }} />
      </div>*/}

      <div>
        <div>
          <PositiveIndicators
            protocolData={protocolData}
            protocolState={protocolState}
            setProtocolState={setProtocolState}
            stateId={stateId}
            clearProtocolState={clearProtocolState}
            preSelectProtocolType={selectedProtocolType}
            handleProtocolRedirect={handleProtocolRedirect}
          />
        </div>
      </div>
    </>
  );
};

export default ProtocolResponseFill;
