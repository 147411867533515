import React, { useEffect } from "react";
import TextArea from "../../common/textfield/TextArea";
import { FieldArray, FormikProvider, useFormik } from "formik";

const InitialAssessmentComponent = ({
  protocolData,
  onAssessmentChange,
  protocolState,
}) => {
  const assessmentQuestions = protocolData?.initialAssessmentQuestions || "";

  // Splitting each assessment question at the numbered bullet using regex
  const splitQuestions = assessmentQuestions
    .split(/\n(?=\d{1,2}\.)/) // Split only when a line break is followed by a digit(s) and a period
    .filter(Boolean) // Remove any empty lines
    .map((question) => question.trim()); // Remove extra spaces

  const formik = useFormik({
    initialValues: {
      answers: Array(splitQuestions.length).fill(""),
    },
    onSubmit: () => {
      // No need for onSubmit since we're handling updates on blur
    },
  });

  const autoPopulateAnswers = (protocolState) => {
    if (protocolState?.initialAssessmentQuestions) {
      const answersFromState = protocolState?.initialAssessmentQuestions;

      // Loop through the answers in protocolState and auto-populate formik answers
      answersFromState.forEach((response) => {
        const matchingQuestionIndex = splitQuestions.findIndex(
          (q) => q.includes(response.question.split(":")[0]) // Compare question numbers to match
        );

        if (matchingQuestionIndex !== -1 && response.answer) {
          // Set the value in formik for the corresponding question
          formik.setFieldValue(
            `answers[${matchingQuestionIndex}]`,
            response.answer
          );
        }
      });
    }
  };

  useEffect(() => {
    // Call autoPopulateAnswers once when component is mounted or protocolState changes
    autoPopulateAnswers(protocolState);
  }, [protocolState]);

  if (!protocolData) {
    return null;
  }

  const handleBlur = (answer, index) => {
    // Only update the parent with answered questions
    if (answer?.trim() !== "") {
      const updatedResponses = formik.values.answers
        .map((answer, i) => {
          if (answer?.trim() !== "") {
            return { question: splitQuestions[i], answer: answer, index: i };
          }
          return null; // Do not include unanswered questions
        })
        .filter((item) => item !== null); // Remove null items (unanswered questions)

      onAssessmentChange(updatedResponses); // Pass updated responses to the parent
    }
  };

  return (
    <>
      <FormikProvider value={formik}>
        <form>
          <div style={{ marginBottom: "60px" }}>
            <FieldArray
              name="answers"
              render={(arrayHelpers) => (
                <>
                  {splitQuestions.map((question, index) => (
                    <div
                      key={index}
                      style={{
                        marginBottom: "20px",
                        border: "1px solid #005da8",
                        borderRadius: "8px",
                        padding: "10px",
                        backgroundColor: "#f0f8ff",
                      }}
                    >
                      <div
                        style={{
                          marginBottom: "10px",
                        }}
                      >
                        {question.trim()}
                      </div>
                      <TextArea
                        value={formik.values.answers[index]}
                        onChange={(e) =>
                          formik.setFieldValue(
                            `answers[${index}]`,
                            e.target.value
                          )
                        }
                        onBlur={() =>
                          handleBlur(formik.values.answers[index], index)
                        }
                        hideRequired={true}
                        style={{
                          width: "100%",
                          boxSizing: "border-box",
                          height: "80px",
                          borderRadius: "8px",
                          border: "1px solid #005da8",
                        }}
                      />
                    </div>
                  ))}
                </>
              )}
            />
          </div>
        </form>
      </FormikProvider>
    </>
  );
};

export default InitialAssessmentComponent;
