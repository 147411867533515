import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table } from "react-bootstrap";
import { Skeleton, TableCell, Tooltip } from "@mui/material";
import { DateSelectorWithoutFormik } from "../../common/textfield/DateSelector";
import CustomPagination from "../../common/components/Pagination/CustomPagination";
import { searchEncounters } from "../api/AppointmentRequests";
import { failed, success } from "../../common/Toastify";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import ViewIcon from "../../../assets/images/view.png";
import "./DashboardEncounterList.css";
import { Button } from "../../common/Button";
import { getEncounterRecord } from "../api/AppointmentRequests";
import { set } from "date-fns";
import { ViewEncounterModal } from "./ViewEncounterModal";
import { ViewDateTime } from "../../../utils/DateSupport";
import { encryptData } from "../../../components/EncryptDecrypt";


const DashboardEncounterList = () => {
    const navigate = useNavigate();
    const user = useSelector((state) => state?.auth?.user);
    const userOrganizationId = useSelector(
        (state) => state?.auth?.user?.["organizations"]?.[0]?.id
    );
    const practitionerId = user?.["custom:practitioner_id"];

    const [encounterData, setEncounterData] = useState([]);
    const [encounterCount, setEncounterCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [encounterStartDate, setEncounterStartDate] = useState(
        moment().subtract(7, "days").format("YYYY-MM-DD")
    );
    const [encounterEndDate, setEncounterEndDate] = useState(moment().format("YYYY-MM-DD"));
    const [page, setPage] = useState(0);
    const rowsPerPage = 5;
    const [isPagination, setIsPagination] = useState(false)
    const [encounterModal, setEncounterModal] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);



    useEffect(() => {
        fetchEncounters();
    }, [encounterStartDate, encounterEndDate, page]);

    const fetchEncounters = () => {
        setIsLoading(true);
        searchEncounters({
            orgId: userOrganizationId,
            start: encounterStartDate,
            end: encounterEndDate,
            practitionerId: practitionerId,
            page: isPagination ? page + 1 : 1,
            pageSize: rowsPerPage,
            type: 'open'
        })
            .then((res) => {
                const sortedData = sortByStartDateDesc(res?.data || []);
                setEncounterData(sortedData);
                setEncounterCount(res?.count?.Count || 0);
            })
            .catch((err) => {
                failed(err?.response?.data?.message || err?.response?.data?.error || err.message);
            })
            .finally(() => setIsLoading(false));
    };


    const sortByStartDateDesc = (data) => {
        return data.sort((a, b) => {
            const dateA = new Date(a.period?.start);
            const dateB = new Date(b.period?.start);
            return dateB - dateA;
        });
    };

    const handleViewEncounterClick = (encounter) => {
        const encounterId = encounter.id;
        getEncounterRecord({ encounterId })
            .then((res) => {
                setSelectedRecord(res?.data);
                setEncounterModal(true);
            })
            .catch((err) => {
                failed(err?.response?.data?.message || err.message);
            });
    };

    const handlePagination = (pageNo) => {
        setPage(pageNo);
        setIsPagination(true);
    };

    const refreshEncounters = () => {
        fetchEncounters(); // Reuse the existing fetch function
    };


    return (
        <div className="dashboard-encounter-list">
            <div className="mnth-date-wrap">
                <DateSelectorWithoutFormik
                    label="Start Date"
                    minDate={""}
                    maxDate={moment(encounterEndDate)}
                    value={moment(encounterStartDate)}
                    handleChange={(val) => setEncounterStartDate(val.format("YYYY-MM-DD"))}
                    type="readonly"
                />
                <DateSelectorWithoutFormik
                    label="End Date"
                    minDate={moment(encounterStartDate).add(1, "days")}
                    maxDate={moment()}
                    value={moment(encounterEndDate)}
                    handleChange={(val) => setEncounterEndDate(val.format("YYYY-MM-DD"))}
                    type="readonly"
                />
            </div>

            <div className="table-wrap">
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Start Date</th>
                            <th>Patients Name</th>
                            <th>Status</th>
                            <th>Report</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading
                            ? Array.from({ length: rowsPerPage }).map((_, idx) => (
                                <tr key={idx}>
                                    {Array.from({ length: 5 }).map((_, cellIdx) => (
                                        <td key={cellIdx}>
                                            <Skeleton animation="wave" />
                                        </td>
                                    ))}
                                </tr>
                            ))
                            : encounterData?.length > 0
                                ? encounterData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((encounter) => {
                                    const { id, subject, period, status } = encounter;
                                    const patientName = subject?.display || "-";

                                    return (
                                        <tr key={id}>
                                            <td>{period?.start ? ViewDateTime(period?.start) : '-'}</td>
                                            <td>
                                                {patientName !== "-" ? (
                                                    <span
                                                        onClick={() => window.open(`/app/patients-details?patientId=${subject?.reference?.split("/")[1]}`, '_blank')}
                                                        style={{ cursor: "pointer", textDecoration: "underline", textTransform: "capitalize" }}
                                                    >
                                                        {patientName}
                                                    </span>
                                                ) : (
                                                    "-"
                                                )}
                                            </td>
                                            <td>{status || "-"}</td>
                                            <td>
                                                {status !== "cancelled" && !(status === "in-progress-intake" && patientName === "-") && (
                                                    <div className="action-wrap" style={{ justifyContent: "start" }}>
                                                        <div
                                                            onClick={() => {
                                                                navigate("/app/encounter-details", {
                                                                    state: { encounterId: id, notPatientDashboard: true },
                                                                })
                                                            }}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <Tooltip title={"View"}>
                                                                <Link to="" variant="primary" className="view-btn">
                                                                    <img src={ViewIcon} alt="View" />
                                                                </Link>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                ) || "-"}
                                            </td>
                                            <td>
                                                <div
                                                    className="action-wrap"
                                                    style={{
                                                        justifyContent: "start",
                                                    }}
                                                >
                                                    <Button
                                                        title="View"
                                                        onClick={() => handleViewEncounterClick(encounter)}
                                                    >
                                                        View
                                                    </Button>
                                                </div>


                                            </td>
                                        </tr>
                                    );
                                })
                                : (
                                    <tr>
                                        <TableCell colSpan={5} style={{ textAlign: "center" }}>
                                            No data available
                                        </TableCell>
                                    </tr>
                                )}
                    </tbody>
                </Table>

                {encounterData?.length > 0 && Array.isArray(encounterData) ? (
                    <CustomPagination
                        tableData={encounterData}
                        totalRows={encounterCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handlePage={handlePagination}
                    />
                ) : null}

                {encounterModal && selectedRecord && (
                    <ViewEncounterModal
                        data={selectedRecord}
                        onShow={encounterModal}
                        onHide={() => setEncounterModal(false)}
                        refreshEncounters={refreshEncounters} // Pass the refresh function
                    />
                )}

            </div>
        </div>
    );
};

export default DashboardEncounterList;
